/*------------------------------------*\
    $SEARCH MODULE
\*------------------------------------*/

.search-module {
  /* mobile rules first */
  max-width: 60vw;

  @include media-query(lap-and-up) {
    max-width: 100%;
    background: #ffffff;
    box-shadow: 0 1px 5px 0 rgba(39, 50, 56, 0.2);
    border-radius: 2px;
    & > div:first-child {
      input {
        border-radius: 2px 2px 0 0;
      }
    }
    & > button:last-child {
      border-radius: 0 0 2px 2px;
    }
    .button.full-width {
      text-align: left;
      @include clearfix();
      @include remSize(padding, 0 16);
      .icon {
        vertical-align: top;
        float: right;
        line-height: 42px;
        margin-top: 0;
        + span {
          margin: 0;
        }
      }
    }
  }
}
