.location-module-content {
  @include remSize(padding, 24 24 90 24);
  background-color: $white;
  box-sizing: border-box;

  @media (max-width: $large-smartphone) {
    @include remSize(padding, 24 12 90 12);
    min-height: 100%;
    background-color: $grey_100;
  }

  .Select {
    border: 1px solid $grey_300;
    box-shadow: none;
  }

  .select-wrapper {
    + .select-wrapper,
    + .double-input-wrapper {
      @include remSize(margin-top, 20);
    }
  }

  .double-input-wrapper {
    border-bottom: 0;

    @media (max-width: $large-smartphone) {
      display: flex;
    }

    .left-column {
      @include remSize(padding, 0 16 0 0);

      @media (max-width: $large-smartphone) {
        @include remSize(padding, 0 8 0 0);
        width: 50%;
      }
    }

    .right-column {
      @include remSize(padding, 0 0 0 16);

      @media (max-width: $large-smartphone) {
        @include remSize(padding, 0 0 0 8);
        width: 50%;
      }
    }

    + .form-field {
      @include remSize(margin-top, 20);
    }
  }

  .form-field {
    + .collapsable-item {
      @include remSize(margin-top, 20);
    }
  }

  .collapsable-item {
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -24px;
      bottom: calc(100% - 1px);
      left: -24px;
      background-color: $grey_200;
    }

    &::after {
      display: none;
      top: calc(100% - 1px);
      bottom: 0;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  .collapse-title {
    display: block;
    @include remSize(padding, 15 20 15 0);
    @include fontSize(14);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    color: $grey_500;
  }

  .controls-area {
    position: absolute;
    left: 0;
    bottom: 0;
    @include remSize(padding, 24);
    width: 100%;
    text-align: center;
    background-color: $white;
    border-top: 1px solid $grey_300;
    box-sizing: border-box;

    .button {
      @include remSize(min-width, 100);

      + .button {
        @include remSize(margin-left, 24);
      }
    }
  }

  .collapsable-content {
    @include remSize(margin, 8 0 24 0);
  }

  .clean-button {
    display: block;

    &.right-aligned {
      display: block;
      margin-bottom: 20px;
      width: 100%;
      text-align: right;
    }

    + .clean-button {
      @include remSize(margin-top, 24);
    }
  }

  .location-fav {
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}
