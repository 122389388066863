$time: 0.3s;

.table-finder-wrapper {
  display: block;
  position: relative;
  @include remSize(width, 40);
  @include remSize(height, 40);
  transition: width $time;
  will-change: width;

  &.is-opened {
    @include remSize(width, 232);

    .button-secondary {
      border-left: 1px solid transparent;

      .icon {
        color: $grey_300;
        @include fontSize(12);
      }
    }
  }

  .button-secondary {
    display: block !important;
    position: absolute;
    top: 0;
    right: 0;
    @include remSize(padding, 0 9);
    @include remSize(height, 40);
    background-color: $white;
    border: 1px solid $grey_300;
    box-sizing: border-box;
    transition: border $time;
  }

  .form-label {
    display: none;
  }

  .form-input {
    &:focus {
      border: 1px solid $grey_300;
    }
  }
}
