//Asset block

.asset-detail-print {
  .map-view {
    float: right;
    width: 8cm;
    margin: 3em 0;
  }
  .table-id-section {
    float: left;
    width: 10cm;
  }
  .table-data-section {
    clear: both;
  }
  .document-section {
    overflow: hidden;
  }
  .asset-img {
    width: 48%;
    margin: 2% 0;
    float: left;
    padding: 0 1%;
  }
}

.description-table {
  page-break-inside: avoid;
}
