.action-form-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  height: 100%;
  @include remSize(padding, 0 48 32 48);
  box-sizing: border-box;

  @media (max-width: $large-smartphone) {
    padding: 0;
  }

  .form-sidebar {
    position: sticky;
    top: 0;
    @include remSize(padding, 24 0 24 24);
    width: 200px;

    @media (max-width: $large-smartphone) {
      display: none;
    }

    + .form-content {
      @include remSize(margin-left, 80);
      @include remSize(padding, 24 24 24 0);

      @media (max-width: $large-smartphone) {
        @include remSize(margin-left, -12);
        min-width: calc(100% + 24px);
        @include remSize(padding, 0 0 0 12);
        overflow: hidden;
      }
    }
  }

  .back-button {
    + .actions-list {
      @include remSize(margin-top, 12);
    }
  }

  .actions-list {
    min-width: 200px;

    .link {
      display: block;
      position: relative;
      width: 100%;
      @include remSize(padding, 12 16);
      text-align: left;
      color: $orange_500;
      background-color: $white;
      border: 1px solid $grey_300;
      box-sizing: border-box;
      /* overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; */

      &.active {
        color: $grey_800;
        pointer-events: none;
      }

      &.active-click {
        color: $grey_800;
      }

      &:hover {
        color: $white;
        background-color: $orange_500;
      }

      + .link {
        border-top: 0;
      }

      + .collapsable-item {
        .link {
          border-top: 0;
        }
      }
    }

    .collapsable-item {
      .link {
        color: $orange_500;
        cursor: pointer;
        border-bottom: 0;

        &:last-child {
          border-bottom: 1px solid $grey_300;
        }

        &.active {
          color: $grey_800;
          pointer-events: none;
        }

        &.active-click {
          color: $grey_800;
        }

        &.empty {
          color: $orange_200;
        }

        &:hover {
          color: $white;
          background-color: $orange_500;
        }
      }

      .collapse-button {
        color: $orange_500;
      }

      + .link {
        border-top: 0;
      }
    }

    .collapsable-header {
      padding: 0;

      .link {
        border-bottom: 1px solid $grey_300;
      }

      &:hover {
        .link {
          color: $white;
          background-color: $orange_500;
        }

        .collapse-button {
          color: $white;
        }
      }
    }
  }

  .find-list {
    min-width: 200px;

    .search-field {
      + .search-field {
        @include remSize(margin-top, 8);
      }
    }
  }

  .form-content {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .work-order-bar-wrapper {
      position: sticky;
      top: 24px;
      @include remSize(margin-bottom, 24);
      z-index: 1;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -24px;
        left: 0;
        width: 100%;
        height: 24px;
        background-color: $grey_100;
      }
    }

    .form-title {
      display: block;
      position: relative;
      margin: 0 auto;
      @include remSize(margin-bottom, 32);
      @include fontSize(24);
      font-weight: $regular;
      @include remSize(letter-spacing, 0.2);
      color: $grey_800;
    }

    .add-work-order {
      display: block;
      position: relative;
      text-align: right;

      .button {
        position: relative;
        margin: 0 auto;
        min-width: 125px;
      }

      .form-title {
        text-align: left;

        + .button {
          @include remSize(margin-top, 24);
        }
      }

      + .work-order-card-wrapper {
        @include remSize(margin-top, 16);
      }

      + .collapsable-item {
        @include remSize(margin-top, 32);
      }
    }

    .number-info {
      display: block;
      @include fontSize(16);
      font-weight: $regular;
      @include remSize(letter-spacing, 0.2);
      color: $grey_500;
      text-align: left;

      span {
        font-weight: $medium;
      }

      + .button {
        @include remSize(margin-top, 8);
      }
    }

    .form-field,
    .link,
    .check-item,
    .coordinates-input-wrapper {
      + .form-field,
      + .link,
      + .coordinates-input-wrapper {
        @include remSize(margin-top, 32);
      }
    }

    .Select,
    .Select-menu-outer {
      box-shadow: none;
      border: 1px solid $grey_300;
      box-sizing: border-box;
    }

    .Select-menu-outer {
      margin-left: -1px;
      width: calc(100% + 2px);
    }

    .select-wrapper {
      + button.link {
        display: block;
        @include remSize(margin-top, 8);
        width: 100%;
        text-align: right;
      }
    }

    .collapsable-item {
      background-color: $white;

      + .collapsable-item {
        .collapsable-header {
          border-top: 0;
        }
      }
    }

    .collapsable-header {
      @include remSize(padding, 20 40 20 24);
      box-sizing: border-box;
      border: 1px solid $grey_200;
    }

    .collapse-button {
      top: 8px;
      right: 12px;
      color: $grey_400;
    }

    .collapsable-content {
      @include remSize(padding, 32 24);
      border: 1px solid $grey_200;
      border-top: 0;
      box-sizing: border-box;

      .title {
        @include fontSize(16);
        font-weight: $medium;
        @include remSize(letter-spacing, 0.2);
        color: $grey_800;

        + .description {
          @include remSize(margin-top, 8);
        }
      }

      .description {
        @include fontSize(14);
        font-weight: $regular;
        @include remSize(letter-spacing, 0.2);
        color: $grey_800;

        + .check-item {
          @include remSize(margin-top, 24);
        }
      }
    }
  }

  .work-order-card-wrapper {
    + .work-order-card-wrapper {
      @include remSize(margin-top, 16);
    }
  }

  .indicator-wrapper {
    .collapsable-header {
      border-top: 1px solid $grey_300;
      border-right: 0;
      border-bottom: 0;
      border-left: 0;
    }

    .collapsable-content {
      border: 0;
    }
  }
}
