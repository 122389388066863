/*------------------------------------*\
    $COLLAPSABLE ITEM
\*------------------------------------*/

.collapsable-header {
  position: relative;
  @include remSize(padding, 0.5 40 0.5 0.5);
  min-height: 40px;
  .collapse-button {
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    width: 40px;
    border: none;
    background: transparent;
  }
}

.collapsable-content {
  display: block;
  &.is-collapsed {
    display: none;
  }
}
