.indicator-wrapper {
  background-color: $white;
  border: 1px solid $grey_300;
  border-radius: 2px;
  box-sizing: border-box;

  .card-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @include remSize(padding, 8 16);
    box-sizing: border-box;
  }

  .name {
    display: block;
    position: relative;
    margin: 0;
    @include remSize(padding, 4 0 12 0);
    width: 100%;
    @include fontSize(16);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    color: $grey_800;

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: -16px;
      bottom: 0;
      left: -16px;
      height: 1px;
      background-color: $grey_300;
    }
  }

  .info {
    margin: 0;
    @include remSize(padding, 8 8 0 0);
    width: 50%;
    text-align: left;
    @include fontSize(14);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    color: $grey_400;
    box-sizing: border-box;
  }

  .code {
    margin: 0;
    @include remSize(padding, 8 0 0 8);
    width: 50%;
    text-align: right;
    @include fontSize(14);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    box-sizing: border-box;
  }

  .card-content {
    @include remSize(padding, 24 16);
    border-top: 1px solid $grey_300;
    box-sizing: border-box;

    .description {
      position: relative;
      margin: 0;
      color: $grey_800;
      @include fontSize(14);
      font-weight: $regular;
      @include remSize(letter-spacing, 0.2);

      + .form-field {
        @include remSize(margin-top, 12);
      }
    }
  }

  .form-field {
    + .description {
      @include remSize(margin-top, 24);
    }

    + .collapsable-item {
      @include remSize(margin-top, 80);

      @media (max-width: $large-smartphone) {
        @include remSize(margin-top, 40);
      }
    }
  }

  .collapsable-item {
    @include remSize(margin-left, -16);
    width: calc(100% + 32px);

    @media (max-width: $large-smartphone) {
      @include remSize(margin-left, 0);
      width: 100%;
    }
  }

  .collapsable-header {
    border-top: 1px solid $grey_300;
    border-right: 0;
    border-left: 0;
    box-sizing: border-box;

    @media (max-width: $large-smartphone) {
      @include remSize(padding, 16 0);
      border-top: 0;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -16px;
        width: calc(100% + 32px);
        height: 1px;
        background-color: $grey_300;
      }
    }

    .title {
      margin: 0;
      @include fontSize(14);
      font-weight: $regular;
      @include remSize(letter-spacing, 0.2);
    }
  }

  .collapsable-content {
    @media (max-width: $large-smartphone) {
      @include remSize(padding, 48 0);
    }
  }

  + .indicator-wrapper {
    @include remSize(margin-top, 32);
  }
}
