/*------------------------------------*\
    $ABSOLUTE ALERT CONTAINER
\*------------------------------------*/

.absolute-alert-container {
  z-index: 100000000000;
  position: absolute;
  bottom: 1px;
  right: 1px;
}
