.action-adder-wrapper {
  display: block;
  position: relative;
  width: 100%;
  background-color: $white;
  border: 1px solid $grey_300;
  border-radius: 2px;
  box-sizing: border-box;

  .header-title {
    display: block;
    position: relative;
    margin: 0 auto;
    @include remSize(padding, 12 16);
    @include fontSize(16);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    color: $grey_800;
    border-bottom: 1px solid $grey_300;
    box-sizing: border-box;

    @media (max-width: $large-smartphone) {
      @include fontSize(12);
      color: $grey_500;
      text-transform: uppercase;
    }
  }

  .childs-wrapper {
    position: relative;

    .preview-doc {
      text-align: left;
      border: 0;
      border-bottom: 1px solid $grey_200;

      &.is-opened {
        @include remSize(height, 158);
      }
    }

    .select-wrapper {
      @include remSize(margin, 16);

      .label {
        font-weight: $medium;
      }
    }

    .link {
      @include remSize(padding, 0 16);
    }

    .hidden-input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
    }

    .deleteable-wrapper {
      .link {
        padding: 0;
      }
    }
  }

  .button-field {
    display: block;
    position: relative;
    @include remSize(padding, 24 16);
    text-align: right;
    box-sizing: border-box;

    .button {
      position: relative;
      margin: 0 auto;
      min-width: 128px;
      box-sizing: border-box;

      @media (max-width: $large-smartphone) {
        width: 100%;
      }

      .disabled {
        opacity: 0.6;
        pointer-events: none;
      }
    }
  }
}
