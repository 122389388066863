$time: 0.3s;

.confirmation-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  position: fixed;
  @include remSize(padding, 8 16);
  bottom: 0;
  left: 50%;
  max-width: calc(100% - 32px);
  text-align: center;
  background-color: $white;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(39, 50, 56, 0.3);
  box-sizing: border-box;
  opacity: 0;
  transform: translateX(-50%) translateY(0);
  transition: opacity $time, transform $time;
  /* try to fix this button being "over" the bottom of the asset selector */
  pointer-events: none;

  @media (max-width: $large-smartphone) {
    @include remSize(padding, 16);
    display: block;
    width: 100%;
  }

  &.is-opened {
    opacity: 1;
    transform: translateX(-50%) translateY(-24px);
    pointer-events: all;
  }

  .text {
    display: block;
    position: relative;
    margin: 0 auto;
    @include fontSize(14);
    font-weight: $regular;
    @include remSize(letter-spacing, 0.2);
    color: $grey_500;
    white-space: nowrap;

    + .button {
      margin-left: 16px;
    }

    @media (max-width: $large-smartphone) {
      white-space: normal;

      + .button {
        margin-left: 0;
      }

      ~ .button {
        margin-top: 16px;
      }
    }
  }

  .button {
    min-width: 120px;

    @media (max-width: $large-smartphone) {
      display: inline-block;
      vertical-align: middle;
      width: calc(50% - 4px);
    }

    + .button {
      margin-left: 8px;
    }
  }
}
