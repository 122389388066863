.map-results-wrapper {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .map-container {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 280px;
    max-width: calc(100% - 280px);
  }

  .button-container {
    position: absolute;
    top: 56px;
    right: 120px;
  }
}
