/*------------------------------------*\
    $LISTS
\*------------------------------------*/

.check-list {
  li {
    @include remSize(padding, 8 24 0 24);
  }
  .check-item {
    @include remSize(margin, 0 0 8);
  }
  .collapsable-header {
    min-height: 0;
    .collapse-button {
      top: -10px;
    }
  }
}

.buttons-list {
  .title {
    @include remSize(margin, 14 0 17);
    @include fontSize(16);
    @include remSize(letter-spacing, 0.2);
    font-weight: $medium;
  }
  li {
    button {
      @include remSize(padding, 5 0);
    }
    & + li {
      @include remSize(margin-top, 2);
    }
  }
  &.primary-buttons {
    .search-result {
      color: $primary;
    }
  }
}

.document-list {
  & > .title {
    @include remSize(margin, 14 0 17);
    @include fontSize(16);
    @include remSize(letter-spacing, 0.2);
    font-weight: $medium;
  }
  li {
    & + li {
      @include remSize(margin-top, 16);
    }
  }
}

.empty-list-message {
  border: 1px solid rgba($warning, 0.6);
  border-radius: 3px;
  background: rgba($warning, 0.08);
  @include remSize(padding, 5 30);
  margin: 0 auto;
  @include fontSize(14);
  font-weight: $medium;
  color: $grey_400;
  max-width: 340px;
  text-align: center;
}

.legend-list {
  .title {
    @include fontSize(14);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    @include remSize(margin, 0 0 20);
  }
  li {
    @include remSize(padding, 8 0);
  }
}
