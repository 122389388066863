// LEGEND ITEM

.legend-item {
  white-space: nowrap;
  .image,
  .text {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
  }
  .image {
    width: 32px;
    @include remSize(margin-right, 16);
  }
  .text {
    @include fontSize(14);
    @include remSize(letter-spacing, 0.2);
  }
}
