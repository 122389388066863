/*------------------------------------*\
    $DESCRIPTION TABLE
\*------------------------------------*/

.description-table {
  @include remSize(padding, 16 0);
  .title {
    @include fontSize(16);
    font-weight: $medium;
    color: $text;
    @include remSize(letter-spacing, 0.2);
    margin: 0;
    @include remSize(padding, 0 0 10 24);
  }
  table {
    width: 100%;
    @include fontSize(14);
    @include remSize(letter-spacing, 0.2);
  }
  tr:nth-child(even) {
    background: $grey_100;
  }
  tr:nth-child(odd) {
    background: $white;
  }
  th,
  td {
    @include remSize(padding, 8 24);
  }
  th {
    text-align: left;
    color: $grey_400;
    font-weight: $medium;
    @include remSize(padding-right, 12);
  }
  td {
    text-align: right;
    @include remSize(padding-left, 12);
    word-break: break-word;
  }
}
