$time-fast: 0.25s;
$time: 0.3s;

.action-modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $grey_100;
  z-index: $z-index-modal;

  &.is-detail {
    .modal-header {
      background-color: $grey_800;
    }
  }

  &.has-controls {
    .mobile-form {
      @include remSize(padding-bottom, 100);
    }
  }

  .modal-header {
    display: block;
    position: relative;
    @include remSize(padding, 20 40);
    background-color: $orange_500;
    text-align: center;
    box-sizing: border-box;

    .link {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &:first-child {
        left: 12px;
      }

      &:nth-child(2) {
        right: 12px;
      }

      .icon {
        margin: 0;
      }
    }

    .text {
      display: block;
      position: relative;
      margin: 0 auto;
      @include fontSize(16);
      font-weight: $medium;
      @include remSize(letter-spacing, 0.2);
      color: $white;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .delete-tab {
    position: absolute;
    top: 40px;
    right: 16px;
    background-color: $white;
    border-radius: 2px;
    box-shadow: 0 1px 5px rgba(39, 50, 56, 0.4);
    transform: scale3d(1, 0, 1);
    transform-origin: top;
    transition: transform $time-fast $time-fast;

    &.is-opened {
      transform: scale3d(1, 1, 1);
      transition: transform $time-fast;

      .link {
        opacity: 1;
        transition: opacity $time-fast $time-fast;
      }
    }

    .link {
      display: block;
      position: relative;
      @include remSize(padding, 10 18);
      box-sizing: border-box;
      opacity: 0;
      transition: opacity $time-fast;

      + .link {
        border-top: 1px solid $grey_200;
      }
    }
  }

  .modal-confirmation-message {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include remSize(padding, 20 12);
    text-align: center;
    background-color: $white;
    box-sizing: border-box;
    opacity: 0;
    pointer-events: none;
    transition: opacity $time;

    &.is-opened {
      opacity: 1;
      pointer-events: all;
    }

    .text {
      display: block;
      position: relative;
      margin: 0 auto;
      width: 100%;
      @include fontSize(16);
      font-weight: $regular;
      @include remSize(letter-spacing, 0.2);

      + .controls {
        @include remSize(margin-top, 12);
      }
    }

    .button {
      display: block;
      position: relative;
      width: 100%;

      + .button {
        @include remSize(margin-top, 12);
      }
    }
  }

  .modal-content {
    display: block;
    position: relative;
    height: calc(100% - 57px);
    overflow-y: auto;
  }

  .mobile-form {
    @include remSize(padding, 20 12);
    box-sizing: border-box;

    .form-field,
    .link,
    .check-item {
      + .form-field,
      + .link {
        @include remSize(margin-top, 32);
      }

      + .title {
        @include remSize(margin-top, 80);
      }
    }

    .title {
      @include fontSize(18);
      font-weight: $medium;
      @include remSize(letter-spacing, 0.2);

      + .description {
        @include remSize(margin-top, 12);
      }
    }

    .description {
      @include fontSize(16);
      font-weight: $regular;
      color: $grey_400;
      @include remSize(letter-spacing, 0.2);

      + .check-item {
        @include remSize(margin-top, 32);
      }
    }

    .Select,
    .Select-menu-outer {
      box-shadow: none;
      border: 1px solid $grey_300;
      box-sizing: border-box;
    }

    .Select-menu-outer {
      margin-left: -1px;
      width: calc(100% + 2px);
    }

    .select-wrapper {
      + button.link {
        display: block;
        @include remSize(margin-top, 8);
        width: 100%;
        text-align: right;
      }
    }

    .sap-column-wrapper {
      @media (max-width: $large-smartphone) {
        .title {
          @include fontSize(14);
        }
      }
    }
  }

  .find-list {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .search-field {
      width: calc(50% - 8px);

      &:nth-child(2n) {
        ~ .search-field {
          @include remSize(margin-top, 16);
        }
      }
    }

    + .number-info {
      @include remSize(margin-top, 40);
    }
  }

  .number-info {
    display: block;
    margin: 0 auto;
    @include fontSize(16);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    color: $grey_800;
    text-align: left;

    span {
      font-weight: $semibold;
    }

    + .work-order-card-wrapper {
      @include remSize(margin-top, 32);
    }
  }

  .work-order-card-wrapper {
    + .work-order-card-wrapper {
      @include remSize(margin-top, 16);
    }

    &.reduced {
      + .work-order-card-wrapper {
        @include remSize(margin-top, 8);
      }
    }
  }

  .modal-controls {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    @include remSize(padding, 16 24);
    @include remSize(min-height, 80);
    background-color: $white;
    border-top: 1px solid $grey_300;
    box-sizing: border-box;
  }

  .controls-wrapper {
    display: block;
    width: 100%;
    text-align: center;

    .button {
      @include remSize(min-width, 120);

      &.button-tertiary {
        width: 100%;
      }

      + .button {
        @include remSize(margin-left, 12);
      }
    }
  }
}
