/*------------------------------------*\
    $DOCUMENT ITEM
\*------------------------------------*/

.document-item {
  display: flex;
  flex-direction: row;
  align-content: center;
  .img {
    width: 80px;
    height: 80px;
    border-radius: 2px;
    text-align: center;
    overflow: hidden;
    .icon {
      @include fontSize(32);
      color: $grey_400;
      line-height: 78px;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .text {
    flex: 1;
    align-self: center;
    @include remSize(padding-left, 18);
  }
  .title {
    @include fontSize(14);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    margin: 0;
    color: $grey_500;
    .extension {
      @include remSize(margin-left, 8);
    }
    + .description {
      @include remSize(margin-top, 8);
    }
  }
  .description {
    @include fontSize(13);
    @include remSize(letter-spacing, 0.2);
    font-weight: $regular;
    color: $grey_400;
    margin: 0;
  }
  &.document-image {
  }
  &.document-doc {
    .img {
      width: 78px;
      height: 78px;
      border: 1px solid $grey_300;
    }
  }
}
