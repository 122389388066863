/*------------------------------------*\
    $MOVE ASSET CONFIRMATION
\*------------------------------------*/

.move-asset-confirmation {
  @include remSize(padding, 16 24);
  .back-button {
    @include remSize(margin-bottom, 22);
  }
  .confirmation-alert {
    .text {
      color: $grey_500;
    }
  }
}
