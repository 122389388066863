.forecast-controls-wrapper {
  display: block;
  position: fixed;
  top: 57px;
  left: 0;
  width: 100%;
  background-color: $white;
  z-index: 2;

  + .sap-form-content {
    @include remSize(margin-top, 64);
  }

  &.is-left {
    ~ .sap-form-content {
      transform: translateX(0);
    }
  }

  &.is-right {
    ~ .sap-form-content {
      transform: translateX(calc(-50% + 6px));
    }
  }

  .button {
    position: relative;
    @include remSize(padding, 20);
    display: inline-block;
    width: 50%;
    box-sizing: border-box;

    span {
      @include fontSize(16);
      font-weight: $regular;
      @include remSize(letter-spacing, 0.2);
      color: $grey_500;
    }

    &.active {
      box-shadow: inset 0 -4px 0 0 $orange_500;
      pointer-events: none;

      span {
        font-weight: $medium;
        color: $grey_800;
      }
    }
  }
}
