.double-input-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid $grey_200;

  @media (max-width: $large-smartphone) {
    display: block;
  }

  .delete-area {
    display: block;
    @include remSize(margin-top, 14);
    width: 100%;
    @include remSize(min-height, 20);
    text-align: right;

    .icon {
      @include remSize(margin-right, 0);
    }
  }

  .left-column,
  .right-column {
    @include remSize(padding, 16 8 32 16);
    width: 50%;
    box-sizing: border-box;

    @media (max-width: $large-smartphone) {
      @include remSize(padding, 16 12 0 12);
      width: 100%;
    }

    .select-wrapper {
      margin: 0;
    }

    .Select-control {
      @include remSize(min-height, 38);
    }
  }

  .right-column {
    @include remSize(padding, 16 16 32 8);

    @media (max-width: $large-smartphone) {
      @include remSize(padding, 24 12 32 12);
      width: 100%;
    }
  }
}
