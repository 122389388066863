/*------------------------------------*\
    $BASE
\*------------------------------------*/

/* Tipos */

// @include font-face('Montserrat', 'montserrat-regular-webfont', 400, normal);
// @include font-face('Montserrat', 'montserrat-medium-webfont', 500, normal);
// @include font-face('Montserrat', 'montserrat-semibold-webfont', 600, normal);
// @include font-face('Montserrat', 'montserrat-bold-webfont', 700, normal);
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@include font-face-old('sgaIcons', 'sgaIcons');

/* Global */

html {
  width: 100%;
  box-sizing: border-box;
}

body {
  width: 100%;
  box-sizing: border-box;
  font-size: $document-font-size;
  background: $baseBg;
  color: $text;
  font-family: $font;
  line-height: $base-lineHeight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::selection {
    background: $primary;
    color: $white;
  }
  ::-moz-selection {
    background: $primary;
    color: $white;
  }

  /* Content */

  iframe {
    max-width: 100%;
  }

  img {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
  }

  ul,
  ol,
  dl,
  dd {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  p {
    line-height: 1.1;
  }

  a {
    color: inherit;
    text-decoration: none;
    &:hover,
    &:focus {
      outline: none;
    }
  }

  p a {
    color: $primary;
  }

  form {
    margin: 0;
  }

  button,
  input,
  textarea,
  select {
    -webkit-appearance: none;
    &:active,
    &:focus {
      outline: none;
    }
  }

  button[disabled],
  input[disabled] {
    cursor: default;
  }

  textarea {
    overflow: auto;
    vertical-align: top;
  }

  button,
  input,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
  }

  input[type='checkbox'],
  input[type='radio'] {
    width: auto;
  }
}

/* Helpers */

.reader-only {
  @include hide-content;
}
