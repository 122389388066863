/*------------------------------------*\
    $OVERLAY
\*------------------------------------*/

.overlay {
  height: 100%;
  width: 100%;
  z-index: $z-index-overlay;
  background: rgba($grey_700, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
