.data-table {
  position: relative;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
  z-index: 0;

  &.has-pagination {
    @include remSize(padding-bottom, 148);
    box-sizing: border-box;
  }

  .table-header {
    @include fontSize(12);
    font-weight: $regular;
    @include remSize(letter-spacing, 0.2);
    color: $grey_400;
    text-transform: uppercase;

    td {
      position: sticky;
      top: 8px;
      padding: 0 24px;
      vertical-align: middle;
      background-color: $grey_100;
      box-sizing: border-box;
      z-index: 1;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        @include remSize(margin-top, -64);
        width: 100%;
        @include remSize(height, 64);
        background-color: $grey_100;
        pointer-events: none;
      }

      &:last-child {
        &::before {
          width: calc(100% + 30px);
        }
      }

      span {
        display: block;
        @include remSize(padding-bottom, 10);
        box-sizing: border-box;

        &.red {
          color: $table-red;
        }

        &.yellow {
          color: $table-yellow;
        }

        &.green {
          color: $table-green;
        }

        &.blue {
          color: $table-blue;
        }
      }
    }
  }

  .table-content {
    @include fontSize(14);
    font-weight: $regular;
    @include remSize(letter-spacing, 0.2);
    color: $grey_600;
    z-index: 0;

    tr {
      background-color: $white;
    }

    td {
      vertical-align: middle;
      padding: 14px 24px;
      border-top: 1px solid $grey_200;
      border-bottom: 1px solid $grey_200;
      box-sizing: border-box;

      &:first-child {
        border-left: 1px solid $grey_200;
        border-radius: 2px 0 0 2px;
      }

      &:last-child {
        border-right: 1px solid $grey_200;
        border-radius: 0 2px 2px 0;
      }

      span {
        &.bold {
          font-weight: $bold;
        }

        &.fixed {
          white-space: nowrap;
        }

        &.red {
          color: $table-red;
        }

        &.yellow {
          color: $table-yellow;
        }

        &.green {
          color: $table-green;
        }

        &.blue {
          color: $table-blue;
        }
      }

      &.has-eye {
        .icon {
          @include fontSize(24);
        }
      }
    }

    .check-item {
      margin: 0;
    }

    .select-wrapper {
      @include remSize(min-width, 160);
    }

    .Select,
    .Select-menu-outer {
      box-shadow: none;
      border: 1px solid $grey_300;
    }

    .Select-menu-outer {
      margin-left: -1px;
      width: calc(100% + 2px);
    }

    .Select-control {
      @include remSize(min-height, 0);
      @include remSize(max-height, 32);
    }

    .Select-placeholder {
      line-height: 32px;
    }

    img {
      @include remSize(width, 32);
      @include remSize(height, 32);
    }

    .color-badget {
      display: block;
      @include remSize(width, 32);
      @include remSize(height, 32);
      border-radius: 32px;

      &.black {
        background-color: $grey_800;
      }

      &.gray {
        background-color: $grey_300;
      }

      &.green {
        background-color: $green;
      }

      &.blue {
        background-color: $blue;
      }
    }
  }

  + .pagination-container {
    position: absolute;
    @include remSize(padding, 32);
    bottom: 0;
    left: 0;
    width: 100%;
    @include remSize(height, 148);
    background-color: $grey_100;
    box-sizing: border-box;
  }
}
