.by-year-table-wrapper {
  display: block;
  position: relative;
  @include remSize(padding-bottom, 8);
  box-sizing: border-box;

  .table-description {
    display: block;
    position: relative;
    margin: 0 auto;
    @include fontSize(14);
    font-weight: $regular;
    @include remSize(letter-spacing, 0.2);
    color: $grey_600;

    + .by-year-table {
      @include remSize(margin-top, 6);
    }
  }
}

.by-year-table {
  display: block;
  position: relative;
  width: 100%;

  th,
  td {
    @include fontSize(14);
    font-weight: $regular;
    @include remSize(letter-spacing, 0.2);
    color: $grey_400;
    text-align: right;
    box-sizing: border-box;
  }

  td {
    @include remSize(padding-left, 8);
    box-sizing: border-box;
  }
}
