.location-favs-form {
  display: block;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: -24px;
    bottom: -24px;
    left: -24px;
    height: 1px;
    background-color: $grey_200;
  }

  &.location-favs-form--open {
    .location-favs-form__form__input {
      display: block;
    }
  }

  &.location-favs-form--has-children {
    &::after {
      bottom: 0;
    }
  }

  &__form__input {
    display: none;
    position: relative;
    margin-top: 20px;
    margin-bottom: 40px;

    .form-field {
      margin-bottom: 16px;
    }
  }

  &__content {
    display: block;
    position: relative;
    margin-top: 20px;
  }
}

.location-fav {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: -24px;
    width: calc(100% + 48px);
    height: 1px;
    background-color: $grey_200;
  }

  .clean-button {
    margin: 0;
    padding: 12px 0;
    padding-right: 12px;
    text-align: left;
    color: $grey_500;
    box-sizing: border-box;

    + .clean-button {
      margin-top: 0;
      padding: 0;
      width: 20px;
      height: 20px;
      text-align: center;
      background-color: $grey_200;
      border-radius: 20px;

      .icon {
        margin-right: 0;
        transform: scale(0.5);
      }
    }
  }
}
