/*------------------------------------*\
    $ALERTS
\*------------------------------------*/

.alert {
  display: inline-block;
  background: $secondary;
  position: relative;
  white-space: nowrap;
  @include remSize(padding, 8 30 8 8);
  &.full-width {
    display: block;
  }
  .icon {
    display: inline-block;
    vertical-align: top;
    @include fontSize(30);
    @include remSize(padding-right, 8);
  }
  .icon-info {
    color: $info;
  }
  .icon-success {
    color: $success;
  }
  .icon-warning {
    color: $warning;
  }
  .icon-error {
    color: $error;
  }
  .icon-close {
    @include fontSize(12);
    padding: 0;
  }
  .alert-close {
    position: absolute;
    top: 0;
    right: 0;
    @include remSize(padding, 10);
    color: $white;
    line-height: 1;
  }
  &.loading-alert {
    @include remSize(padding-right, 8);
    .loading {
      height: 42px;
      margin: -6px 10px -8px 0;
      color: $white;
      vertical-align: top;
      &:after {
        line-height: 42px;
        @include fontSize(32);
      }
    }
    .alert-content {
      max-width: calc(100% - 32px);
    }
  }
}
.alert-content {
  display: inline-block;
  vertical-align: text-bottom;
  white-space: normal;
  max-width: calc(100% - 30px);
  @include fontSize(14);
  font-weight: $medium;
  color: $grey_400;
  @include remSize(letter-spacing, 0.22);
}
.alert-title {
  @include fontSize(14);
  color: $white;
  margin: 0;
  text-transform: capitalize;
  + .alert-text {
    @include fontSize(13);
    font-weight: $regular;
  }
}
.alert-text {
  margin: 0;
}

.confirmation-alert {
  .text {
    @include fontSize(16);
    font-weight: $regular;
    @include remSize(letter-spacing, 0.2);
    margin: 0;
    color: $grey_500;
  }
  .actions {
    text-align: center;
    @include remSize(margin, 28 auto 0);
    max-width: 400px;
    button {
      width: calc(50% - 12px);
      & + button {
        @include remSize(margin-left, 24);
      }

      @media (max-width: $large-smartphone) {
        @include remSize(padding, 0 4);
        min-width: calc(50% -4px);

        + .button {
          @include remSize(margin-left, 8);
        }
      }
    }
  }
}
