.user-status-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  &.is-online {
    &::before {
      background-color: $green;
    }
  }

  &::before {
    content: '';
    display: block;
    position: relative;
    @include remSize(margin-right, 8);
    @include remSize(width, 8);
    @include remSize(height, 8);
    background-color: $red;
    border-radius: 8px;
  }

  span {
    display: block;
    position: relative;
  }
}
