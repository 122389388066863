/*------------------------------------*\
    $MAP LEGEND
\*------------------------------------*/

$time: 0.3s;

.map-legend-container {
  position: absolute;
  right: 10px;
  top: 96px;
  height: calc(100% - 120px);
  pointer-events: none;
  z-index: $z-index-overlay;

  @media (max-width: $large-smartphone) {
    display: none;
  }
}

.map-legend {
  width: 136px;
  height: 40px;
  max-height: 100%;
  background: $white;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(39, 50, 56, 0.3);
  pointer-events: all;
  transition: background $time $time * 2, width $time $time * 2,
    height $time $time;
  will-change: background, width, height;
  .toggle-legend-button {
    width: 100%;
    @include fontSize(12);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    color: $grey_500;
    @include remSize(padding, 12 16);
    @include clearfix();
    transition: font-size $time $time, color $time $time * 2, padding $time;
    will-change: font-size, color, padding;
    .text {
      float: left;
    }
    .icon {
      float: right;
      line-height: 1.4;
    }
  }
  &.is-opened {
    width: 504px;
    height: 664px;
    background: rgba($grey_800, 0.85);
    transition: background $time, width $time, height $time $time;
    .toggle-legend-button {
      @include fontSize(16);
      color: $grey_200;
      @include remSize(padding, 16 23 11);
      transition: font-size $time $time, color $time, padding $time $time;
    }
    .legend-header {
      .title {
        width: 50%;
        transition: width 0s 0.2s;
      }
    }
  }
  &.open-up {
    transform-origin: bottom right;
  }
  &.open-left {
    transform-origin: top right;
  }
}

.legend-content {
  @include remSize(padding, 36 40 0);
  height: calc(100% - 85px);
  border-top: 1px solid $grey_500;
  opacity: 0;
  transition: opacity 0.3s;
  will-change: opacity;
  .legend-list {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    .text {
      color: $white;
      width: calc(100% - 48px);
    }
  }
  .is-opened & {
    opacity: 1;
    transition: opacity $time $time * 2;
  }
}

.legend-header {
  display: block;
  position: relative;
  margin: 0 auto;

  .title {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0 auto;
    width: 0;
    @include fontSize(14);
    font-weight: $regular;
    @include remSize(letter-spacing, 0.2);
    color: $grey_400;
  }

  + .legend-lists {
    margin-top: 24px;
  }
}

.legend-lists {
  max-height: calc(100% - 67px);
  overflow-y: auto;
}
