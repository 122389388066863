.select-wrapper {
  display: block;
  position: relative;

  &.has-label {
    padding-top: 20px;
    box-sizing: border-box;
  }

  .label {
    position: absolute;
    top: 0;
    left: 0;
    @include fontSize(12);
    font-weight: $regular;
    @include remSize(letter-spacing, 0.2);
    color: $grey_600;
  }
}

.Select {
  position: relative;
  background: white;
  border-radius: 2px;
  box-shadow: 0 1px 5px 0 rgba(39, 50, 56, 0.4);
}

.Select-control {
  /* display: block; */
  position: relative;
  @include remSize(padding, 0.5 40 0.5 0.5);
  width: 100%;
  min-height: 40px;
  border: 0;
  cursor: pointer;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  @include fontSize(14);
  font-weight: $regular;
  @include remSize(letter-spacing, 0.2);
  line-height: 40px;
  color: $text;
  @include remSize(padding-left, 16);
  overflow: hidden;
  white-space: nowrap;
}

.Select-multi-value-wrapper {
  /* display: block;
  height: 100%;
  max-width: 100%;
  overflow: hidden; */
  font-size: 85%;
}

.Select-input {
  @include remSize(padding, 8 0 0 16);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;

  input {
    @include remSize(padding, 0);
    max-width: 100%;
    height: 100%;
    @include fontSize(14);
    font-weight: $regular;
    @include remSize(letter-spacing, 0.2);
    line-height: 40px;
    color: $text;
    box-sizing: border-box;
  }
}

.Select-arrow-zone {
  display: block;
  position: absolute;
  top: 0;
  right: 0px;
  text-align: center;
  @include remSize(padding, 0);
  width: 24px;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
  .icon {
    position: absolute;
    top: 50%;
    left: 0;
    color: $primary;
    transform: translate3d(0, -50%, 0);
  }
}

.Select-menu-outer {
  border: 0;
  box-shadow: 0 1px 5px 0 rgba(39, 50, 56, 0.4);

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -3px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: $white;
  }
}

.Select-option {
  @include fontSize(14);
  font-weight: $regular;
  @include remSize(letter-spacing, 0.2);
  text-align: left;
  @include remSize(padding, 10 16);
  color: $grey_600;
  width: 100%;
  background-color: $white;

  &:hover {
    background-color: $grey_200;
    font-weight: $medium;
  }

  &.is-focused {
    background-color: $white;

    &:hover {
      background-color: $grey_200;
    }
  }
}
