.table-controls-wrapper {
  display: block;
  position: absolute;
  top: 144px;
  right: 30px;
  left: 30px;
  @include remSize(height, 42);
  background-color: $grey_100;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -64px;
    left: -15px;
    width: calc(100% + 45px);
    height: calc(100% + 64px);
    background-color: $grey_100;
    pointer-events: none;
    z-index: 0;
  }

  &.has-checks {
    .controls {
      .button-secondary {
        display: block;
      }
    }
  }

  &.is-axes {
    .controls {
      display: flex;
      justify-content: flex-end;
    }
  }

  &.is-contracts,
  &.is-config-users,
  &.is-config-signals,
  &.is-config-elements,
  &.is-config-auscultations,
  &.is-config-parameters {
    @include remSize(height, 62);

    .controls {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    .select-wrapper {
      @include remSize(min-width, 160);
    }

    .Select,
    .Select-menu-outer {
      box-shadow: none;
      border: 1px solid $grey_300;
    }

    .Select-menu-outer {
      margin-left: -1px;
      width: calc(100% + 2px);
    }

    + .data-table {
      @include remSize(padding-top, 82);

      .table-header {
        td {
          top: 90px;
        }
      }
    }
  }

  &.is-config-signals,
  &.is-config-elements,
  &.is-config-auscultations,
  &.is-config-parameters {
    @include remSize(height, 40);

    .controls {
      justify-content: flex-end;
    }

    .select-wrapper {
      display: none;
    }

    + .data-table {
      @include remSize(padding-top, 64);

      .table-header {
        td {
          top: 72px;
        }
      }
    }
  }

  + .data-table {
    @include remSize(padding-top, 64);

    .table-header {
      td {
        top: 72px;
      }
    }
  }

  .controls {
    display: block;
    position: relative;
    width: 100%;
    z-index: 1;

    .button-secondary {
      display: none;
      @include remSize(padding, 0 9);
      @include remSize(height, 40);
      background-color: $white;
      border: 1px solid $grey_300;
      box-sizing: border-box;
      transition: border $time;
    }
  }

  .controls-group {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;

    .button {
      max-height: 40px;

      + .button,
      + .table-finder-wrapper {
        @include remSize(margin-left, 16);
      }
    }

    .table-finder-wrapper {
      + .button {
        @include remSize(margin-left, 16);
      }
    }
  }
}
.filters-table {
  display: flex;
  justify-content: space-around;
}
.footer-control {
  clear: both;
  display: block;
  text-align: right;
  padding: 10px 10px;

  .button {
    @include remSize(margin-left, 16);
  }
}

.toolbar-control {
  display: block;
  margin-top: -30px;
  width: 100%;
  text-align: right;
  padding: 0 0 10px 0;

  .button {
    @include remSize(margin-left, 16);
  }
}

.upload-file-window {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 0 0 10px 0;

  .button {
    @include remSize(margin-top, 16);
  }
}
