$time: 0.3s;

.asset-creator-container {
  display: block;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate3d(-10px, -50%, 0);

  @media (max-width: $large-smartphone) {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: none;
    pointer-events: none !important;
    z-index: $z-index-popup;

    &.is-opened {
      z-index: $z-index-modal;
    }

    .assets-container {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate3d(-16px, -50%, 0);
      transition: transform $time $time * 2, width $time $time * 2,
        height $time $time;

      &.is-opened {
        transform: translate3d(0, -50%, 0);
        transition: transform $time, width $time, height $time $time;
      }
    }
  }
}

.assets-container {
  display: block;
  position: relative;
  width: 40px;
  height: 40px;
  background-color: $white;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(39, 50, 56, 0.3);
  transition: width $time $time * 2, height $time $time;
  will-change: width;
  overflow: hidden;
  pointer-events: all;

  &.is-opened {
    width: 240px;
    height: 260px;
    transition: width $time, height $time $time;

    @media (max-width: $large-smartphone) {
      width: 100%;
      height: 100%;
      z-index: $z-index-overlay;
    }

    .launcher {
      border-bottom: 1px solid $grey_200;
      transition: border $time $time * 2;
    }

    .asset-button {
      opacity: 1;
      transition: opacity $time $time * 2;
    }
  }

  .launcher {
    display: block;
    position: relative;
    width: 100%;
    text-align: left;
    background-color: $orange_500;
    box-sizing: border-box;
    transition: border $time;

    span {
      display: inline-block;
      vertical-align: middle;
      @include fontSize(14);
      font-weight: $medium;
      @include remSize(letter-spacing, 0.2);
      color: $white;
    }

    .icon {
      display: inline-block;
      vertical-align: middle;
      @include fontSize(40);
      position: relative;
      color: $orange_500;

      + span {
        @include remSize(margin-left, 8);
      }

      &::before {
        position: relative;
        z-index: 1;
      }

      &::after {
        content: '';
        position: absolute;
        top: 4px;
        bottom: 4px;
        left: 4px;
        right: 4px;
        background: white;
        z-index: 0;
      }
    }
  }

  .asset-list {
    display: block;
    max-height: calc(100% - 38px);
    overflow: hidden;
    overflow-y: auto;
  }

  .asset-button {
    display: block;
    position: relative;
    @include remSize(padding, 0 16);
    @include remSize(padding-right, 32);
    height: 40px;
    width: 100%;
    @include fontSize(14);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    text-align: left;
    color: $grey_600;
    background-color: $white;
    box-sizing: border-box;
    opacity: 0;
    transition: opacity $time;
    will-change: height;

    &:hover {
      background-color: $grey_200;
    }

    &.back-button {
      @include remSize(padding-right, 16);
      @include remSize(padding-left, 32);
      border-bottom: 1px solid $grey_200;
      box-sizing: border-box;

      .text {
        @include fontSize(14);
        font-weight: $medium;
        @include remSize(letter-spacing, 0.2);
        color: $grey_800;
      }

      .icon {
        right: auto;
        left: 12px;
      }
    }

    .text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
    }
  }

  .children-list {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: $white;
    transform: translateX(100%);
    transition: transform $time;
    z-index: 1;

    &.is-opened {
      transform: translateX(0);
    }

    .list {
      display: block;
      height: calc(100% - 40px);
      overflow: hidden;
      overflow-y: auto;
    }
  }
}
