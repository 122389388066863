/*------------------------------------*\
$RECENT SEARCH
\*------------------------------------*/

.recent-search {
  background: $white;

  .header-box {
    display: none;
  }
  .collapsable-header {
    white-space: nowrap;
    > .icon {
      display: inline-block;
      @include fontSize(16);
      color: $grey_300;
      @include remSize(line-height, 40);
      @include remSize(padding, 0 16);
    }
    .text {
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
      @include fontSize(13);
      font-weight: $medium;
      @include remSize(letter-spacing, 0.2);
      width: 100%;
      max-width: calc(100% - 48px);
    }
  }
  .collapsable-content {
    @include remSize(padding, 0 0 0 48);
    margin-top: -4px;
    .search-result {
      display: block;
      @include remSize(padding, 12 0);
      line-height: 1.1;
      width: 100%;
    }
    li + li {
      border-top: 1px solid $grey_300;
    }
  }
  #fav-items {
    @include remSize(padding, 0 12 0 24);
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .recent-list {
    border-bottom: 1px solid $grey_200;
  }

  @media (max-width: $large-smartphone) {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-index-popup;

    &.is-opened {
      display: block;
    }

    .header-box {
      display: block;
      position: relative;
      background: $grey_800;
      height: 56px;
      .close-box {
        position: absolute;
        left: 0;
        top: 0;
        @include remSize(padding, 0 20);
        @include fontSize(19);
        @include remSize(line-height, 56);
        color: white;
        .icon {
          margin-top: -3px;
        }
      }
    }
    .header-title {
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
      @include fontSize(16);
      @include remSize(letter-spacing, 0.2);
      font-weight: $medium;
      text-align: center;
      color: $grey_400;
      margin: 0;
    }
    .collapsable-header {
      position: absolute;
      top: 56px;
      height: 56px;
      width: 50%;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      & > .icon {
        display: none;
      }
      .text {
        max-width: 100%;
        @include fontSize(16);
        font-weight: $regular;
        text-align: center;
        color: $grey_500;
      }
      &.active {
        .text {
          color: $grey_800;
          font-weight: $medium;
        }
      }
      .collapse-button {
        display: block;
        left: 0;
        width: 100%;
        right: auto;
        height: 55px;
        .icon {
          width: 100%;
          height: 56px;
          font-size: 0;
          border-bottom: 1px solid $grey_300;
        }
        .icon-chevron-up {
          box-shadow: inset 0 -3px 0 $primary;
          border-bottom-color: $primary;
        }
      }
    }
    .recent-list .collapsable-header {
      left: 0;
    }
    .fav-list .collapsable-header {
      right: 0;
    }
    .collapsable-content {
      position: absolute;
      top: 112px;
      bottom: 0;
      width: 100%;
      overflow: auto;
      margin: 0;
      padding: 0;
      .search-result {
        @include remSize(padding, 19 0);
      }
      li {
        @include remSize(padding, 0 24);
      }
    }
  }
}
