/*------------------------------------*\
    $TEXTS
\*------------------------------------*/

.link {
  @include fontSize(14);
  font-weight: $medium;
  /* force mobile Safari to detect pointer events */
  cursor: pointer;
  @include remSize(letter-spacing, 0.2);
  .icon {
    @include fontSize(18);
    @include remSize(margin, -3 8 0 0);
    &.icon-star-colored,
    &.icon-star-line {
      @include remSize(margin-top, -4);
    }
  }
}

.link-primary {
  color: $primary;
  &:hover {
    color: $orange_600;
  }
}

.link-secondary {
  color: $secondary;
}

.link-tertiary {
  color: $grey_300;
}

.link-quaternary {
  color: $grey_400;
}

.link-white {
  color: $white;
}

.search-result {
  display: inline;
  margin: 0;
  @include fontSize(14);
  font-weight: $medium;
  @include remSize(letter-spacing, 0.2);
  color: $grey_500;
  text-align: left;
  span + span {
    @include remSize(margin-left, 10);
  }
}
