/*------------------------------------*\
    $ASSETS LIST MODULE
\*------------------------------------*/

.assets-list-module {
  @include remSize(padding, 18 24);
  display: flex;
  height: calc(100% - 36px);
  flex-direction: column;
  .back-button {
    @include remSize(margin, 0 0 8 -4);
    align-self: flex-start;
  }
  .buttons-list {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    ul {
      flex: 1 1 auto;
      overflow: auto;
    }
  }
}
