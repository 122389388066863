/*------------------------------------*\
    $VARIABLES
\*------------------------------------*/

// -------------------------------------
//   Colors
// -------------------------------------

// ORANGES

$orange_100: #f3dcc9;
$orange_200: #f3c59e;
$orange_300: #f4ae75;
$orange_400: #f4974a;
$orange_500: #f58121;
$orange_600: #c4671a;
$orange_700: #fef8f4;

//Additional colors

$green: #11c65b;
$red: #d0021b;
$yellow: #f2c328;
$blue: #4ca3f7;
$table-red: #9f242d;
$table-yellow: #9fa035;
$table-green: #2da032;
$table-blue: #0000ff;

// Greyscale

$white: #fff;
$grey_100: #f5f7f8;
$grey_200: #eceff1;
$grey_300: #cfd8dc;
$grey_400: #8fa4ae;
$grey_500: #607d8b;
$grey_600: #455a64;
$grey_700: #37474e;
$grey_800: #273238;
$black: #000;

// ------- Functional colors ------- //

$white: #fff;
$black: #000;

$primary: $orange_500;
$primaryDark: $orange_600;

$secondary: $grey_800;

$baseBg: $grey_100;

$text: $grey_800;

$success: $green;
$error: $red;
$warning: $yellow;
$info: $blue;
$disabled: $orange_200;
$disabledGrey: $grey_300;

// -------------------------------------
//   Fonts
// -------------------------------------

$font: "Montserrat", Helvetica, Arial, sans-serif;
// $fontSecondary: 'roboto-cond', sans-serif;

$base-lineHeight: normal;

$thin: 100;
$extraLight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extraBold: 800;
$blackFont: 900;

$font-base: 16;
$document-font-size: ($font-base / 16) * 100%;

// Extended font sizes

// $font_size_zeta         : 14;     // h6
// $font_size_epsilon      : 16;     // h5
// $font_size_delta        : 18;     // h4
// $font_size_gamma        : 20;     // h3
// $font_size_beta         : 24;     // h2
// $font_size_alpha        : 28;     // h1

// $font_size_kilo         : 30;     // extra-large
// $font_size_mega         : 38;     // extra-Xlarge
// $font_size_giga         : 46;     // jumbotronik

// -------------------------------------
//   Loading buttons animation
// -------------------------------------

// Values for animations:
//   ellip =  . .. ...
//   dots = ⠋ ⠙ ⠹ ⠸ ⠼ ⠴ ⠦ ⠧ ⠇ ⠏
//   dots2 = ⠋ ⠙ ⠚ ⠞ ⠖ ⠦ ⠴ ⠲ ⠳
//   bar = ▏ ▎ ▍ ▌ ▋ ▊ ▉
//   circle = ◴ ◷ ◶ ◵
//   beam =  =     ==    ===   ====   ===    ==     =
//   bullet =  ●        ●        ●        ●        ●      ●      ●      ●      ●      ●
//   rhomb = ◇◇◇ ◈◇◇ ◇◈◇ ◇◇◈
//   toggle = ⊶ ⊷
//   time = 🕐 🕑 🕒 🕓 🕔 🕕 🕖 🕗 🕘 🕙 🕚 🕛
//   yo-ke-se-tio =  ¯\_(ツ)_/¯   __(ツ)__   ¯\_(ツ)_/¯   __(ツ)__
//   custom = Does'nt load any animation, you must to add your custom one

$loadingAnimation: dots;

// -------------------------------------
//   Buttons dimensions
// -------------------------------------

$button-height: 42px;
$button-width: auto;

// -------------------------------------
//   Z-Index levels
// -------------------------------------

$z-index-toast: 10000;
$z-index-modal: 9000;
$z-index-overlay: 8000;
$z-index-dropdown: 7000;
$z-index-dialog: 6000;
$z-index-popup: 5000;
$z-index-default: 1;
$z-index-deepdive: -99999;

// -------------------------------------
//   Layout
// -------------------------------------

$l-width: 98%;
$l-maxWidth: 975px;

$base-whitespace: 1em;

// -------------------------------------
//   Grid
// -------------------------------------

// inline-block, float o none

$grid: inline-block;

// -------------------------------------
//   Breakpoints
// -------------------------------------

// palm: for handheld devices
// lap: for tablets and netbooks and the like
// portable: for both of the above
// desk: for anything big enough to be deemed stationary

$lap-start: 700;
$desk-start: 970;
//$desk-wide-start: 1200;

$palm-end: $lap-start - 0.01;
$lap-end: $desk-start - 0.01;

$large-tablet: 1024px;
$large-smartphone: 700px;
