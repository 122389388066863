// NAV ITEM

.nav-item {
  display: block;
  @include fontSize(18);
  font-weight: $regular;
  @include remSize(letter-spacing, 0.24);
  color: $grey_100;
  line-height: 26px;
  white-space: nowrap;
  &.active {
    font-weight: $medium;
    color: $grey_100;
    .icon {
      color: $grey_100;
    }
  }
  .icon {
    @include fontSize(24);
    color: $grey_500;
    @include remSize(margin, 0 16 0 0);
  }
  .text {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
  }
  @include media-query(desk) {
    @include fontSize(16);
    color: $grey_400;
    &:hover {
      color: $primary;
      .icon {
        color: $primary;
      }
    }
  }
}
