.react-tabs {
  .tabs {
    min-height: 50px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    border-bottom: 1px solid $grey_200;
    background: $white;
  }

  .tab-item {
    display: flex;
    border-bottom: 4px solid transparent;
    bottom: -1px;
    position: relative;
    list-style: none;
    cursor: pointer;
    align-items: center;
    color: $grey_500;
    &.active {
      border-color: $primary;
      color: $text;
      font-weight: $medium;
    }
    &:hover {
      color: $text;
      font-weight: $medium;
    }
    &.disabled {
      color: $grey_300;
      cursor: default;
      &:hover {
        font-weight: $regular;
      }
    }

    &:focus {
      outline: none;
    }
  }
  .tab-content {
    width: 100%;
    text-align: center;
  }
  &__tab-panel {
    display: none;
    background: white;
    @include remSize(padding, 24);
    &--selected {
      display: block;
    }
  }
}
