//PRINT STYLES

@page {
  margin: 1cm;
}
.print-area {
  display: block;
}

body {
  font-family: 'Calibri', 'Cambria', Arial, sans-serif;
  box-sizing: border-box;
  orphans: 4;
  widows: 3;
  background: none;
}

#app,
.main-container {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

.app-wrapper {
  height: auto;
  background: none;
}

// Temporal
// .main-content,
.box-block,
.map-legend-container,
// 
.nav-container,
#modal-root {
  display: none;
}
.asset-creator-container {
  display: none;
}
.sect-title {
  font-size: 14pt;
  font-weight: 500;
  margin: 3em 0 2em;
}

.sect-subtitle {
  font-size: 12pt;
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5 {
  page-break-after: avoid;
}

p,
li,
pre,
figure,
blockquote,
.table-wrapper {
  page-break-inside: avoid;
}

figure {
  text-align: center;
  margin-top: 3em;
  margin-bottom: 3em;
}

p {
  font-size: 12pt;
  padding: 0 1.5em;
}

figcaption {
  margin-top: 0.5em;
}

.table-wrapper {
  margin: 2em 2em 3em;
}

.table {
  border: 1px solid #333;
  width: 100%;
  border-collapse: collapse;
  font-size: 11pt;
}

.table caption {
  text-align: left;
  margin-bottom: 1em;
  font-size: 12pt;
}

.table .tr:last-child {
  page-break-before: avoid;
}

.table th,
.table td {
  width: 50%;
  border: 1px solid #333;
  padding: 0.5em 0.2em;
  font-size: 11pt;
}

.table tbody th {
  text-align: left;
}

.page-break {
  display: block;
  page-break-before: always;
}

.avoid-page-break {
  page-break-inside: avoid;
}
