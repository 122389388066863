.assets-map-wrapper {
  display: block;
  position: relative;
  margin: 0 auto;
  text-align: left;

  .label {
    @include fontSize(12);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    color: $grey_800;
  }

  .info {
    @include fontSize(16);
    font-weight: $regular;
    @include remSize(letter-spacing, 0.2);
    color: $grey_400;

    span {
      font-weight: $medium;
    }
  }

  .map-container {
    display: block;
    position: relative;
    width: 100%;
    height: 240px;
    background-color: $grey_100;

    @media (max-width: $large-smartphone) {
      height: 320px;
    }
  }
}
