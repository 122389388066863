/*------------------------------------*\
    $ASSET OPTIONS
\*------------------------------------*/

.asset-options {
  position: relative;
  z-index: $z-index-dropdown;
  .asset-options-opener {
    @include fontSize(26);
    color: $primary;
    width: 40px;
    .icon {
      display: block;
      line-height: 40px;
    }
  }
  .asset-options-menu {
    position: absolute;
    top: 100%;
    background: #ffffff;
    box-shadow: 0 1px 5px 0 rgba(39, 50, 56, 0.4);
    border-radius: 2px;
    min-width: 160px;
    display: none;
  }
  &.is-menu-open {
    .asset-options-menu {
      display: block;
    }
  }
  &.is-right {
    text-align: right;
    .asset-options-menu {
      right: 0;
    }
  }
  .asset-option {
    & + .asset-option {
      border-top: 1px solid $grey_200;
    }
    .link {
      display: block;
      width: 100%;
      text-align: left;
      @include remSize(padding, 10 12);
      .icon {
        @include fontSize(20);
      }
    }
    .link-secondary {
      color: $grey_400;
      &:hover {
        color: $secondary;
      }
    }
  }
}
