.deleteable-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  position: relative;
  box-sizing: border-box;

  @media (max-width: $large-smartphone) {
    @include remSize(padding-top, 32);
  }

  &.has-label {
    .link {
      @include remSize(margin-top, 16);
    }
  }

  .children {
    min-width: calc(100% - 32px);

    @media (max-width: $large-smartphone) {
      width: 100%;
    }
  }

  .link {
    @media (max-width: $large-smartphone) {
      position: absolute;
      top: 32px;
      right: 0;
    }
  }
}
