/*------------------------------------*\
    $UPPER MENU
\*------------------------------------*/

.upper-menu {
  background: $secondary;
  height: 80px;
  .logo {
    line-height: 80px;
    @include remSize(padding, 0 30);
    display: inline-block;
  }
  .action-menu {
    display: none;
  }
  @media (min-width: $large-smartphone + 1) {
    height: 48px;
    position: relative;
    .logo {
      width: 109px;
      @include remSize(padding, 0 19);
      line-height: 48px;
    }
    .action-menu {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      @include remSize(padding, 0 16);
      @include fontSize(16);
      color: $grey_400;
      line-height: 48px;
      border: none;
      background: transparent;
      cursor: pointer;
      .icon {
        @include remSize(margin-top, -4);
      }
    }
  }
}
