.work-order-bar-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  @include remSize(padding, 16 24);
  background-color: $grey_500;
  border-radius: 2px;
  box-sizing: border-box;

  &.is-small {
    @include remSize(padding, 8 16);

    .order-name {
      @include fontSize(12);
    }
  }

  .order-name {
    display: block;
    position: relative;
    margin: 0 auto;
    @include fontSize(18);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    color: $white;

    + .order-detail {
      @include remSize(margin-top, 8);
    }
  }

  .order-detail {
    display: block;
    position: relative;
    margin: 0 auto;
    @include fontSize(14);
    font-weight: $regular;
    @include remSize(letter-spacing, 0.2);
    color: $white;
  }

  .order-user {
    display: block;
    position: relative;
    margin: 0 auto;
    @include fontSize(16);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    color: $grey_300;
  }
}
