@charset "UTF-8";

$slick-font-path: './fonts/' !default;
$slick-loader-path: './' !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir='rtl'] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-list {
  .slick-loading & {
    // background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
  }
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  &:hover,
  &:focus {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
  &:before {
    // font-family: $slick-font-family;
    font-size: 20px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: -25px;
  [dir='rtl'] & {
    left: auto;
    right: -25px;
  }
  &:before {
    content: $slick-prev-character;
    [dir='rtl'] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: -25px;
  [dir='rtl'] & {
    left: -25px;
    right: auto;
  }
  &:before {
    content: $slick-next-character;
    [dir='rtl'] & {
      content: $slick-prev-character;
    }
  }
}

// Own styles
.slider-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 600px;
  text-align: center;
}

.slick-slider {
  position: relative;
  margin: 0 auto;
  padding: 0 58px;
  height: 100%;
  text-align: center;
  box-sizing: border-box;

  + .slider-counter {
    margin-top: 48px;

    @media (max-width: $large-smartphone) {
      margin-top: 16px;
    }
  }
}

.slick-list,
.slick-track,
.slick-slide {
  display: block;
  position: relative;
  margin: 0 auto;
  height: 100%;
}

.slide-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.slick-prev,
.slick-next {
  width: 10px;
  height: 18px;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 3px;
    height: 11.5px;
    background-color: $orange_500;
    opacity: 1;
  }
}

.slick-prev {
  left: 0;

  &::before {
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0) rotate(45deg);
  }

  &::after {
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0) rotate(-45deg);
  }
}

.slick-next {
  right: 0;

  &::before {
    top: 0;
    right: 50%;
    transform: translate3d(-50%, 0, 0) rotate(-45deg);
  }

  &::after {
    bottom: 0;
    right: 50%;
    transform: translate3d(-50%, 0, 0) rotate(45deg);
  }
}

.slider-counter {
  display: block;
  position: relative;
  margin: 0 auto;
  @include fontSize(14);
  font-weight: $regular;
  @include remSize(letter-spacing, 0.2);
  line-height: 17px;
  color: $text;
}
