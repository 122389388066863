.monetary-field-wrapper {
  display: block;
  position: relative;

  &.empty {
    &::after {
      color: $grey_300;
    }
  }

  &.is-fixed {
    &::after {
      color: $orange_200;
    }
  }

  &::after {
    content: attr(data-currency);
    display: block;
    position: absolute;
    top: 31px;
    right: 17px;
    @include fontSize(14);
    font-weight: $regular;
    color: $text;
  }

  .form-input {
    @include remSize(padding-right, 32);
  }
}
