$time: 0.3s;

.work-order-card-wrapper {
  display: block;
  position: relative;
  background-color: $white;
  border: 1px solid $grey_300;
  border-radius: 2px;

  &.reduced {
    .tags-field {
      @include remSize(padding, 0 12);
      border-top: 0;
      min-height: 0;

      &.is-opened {
        @include remSize(padding, 12);
        transition: padding $time;
      }

      .confirmation {
        @media (max-width: $large-smartphone) {
          @include remSize(padding-right, 0);
        }
      }
    }

    .link {
      transform: translateY(-70px);
    }
  }

  .info-field {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;
    @include remSize(padding, 16);
    box-sizing: border-box;
    cursor: pointer;
  }

  .order-name {
    display: block;
    position: relative;
    margin: 0 auto;
    @include fontSize(18);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    color: $grey_800;

    + .order-detail {
      @include remSize(margin-top, 8);
    }
  }

  .order-detail {
    display: block;
    position: relative;
    margin: 0 auto;
    @include fontSize(14);
    font-weight: $regular;
    @include remSize(letter-spacing, 0.2);
    color: $grey_400;
  }

  .order-user {
    display: block;
    position: relative;
    margin: 0 auto;
    @include fontSize(16);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    color: $grey_400;
  }

  .tags-field {
    display: block;
    position: relative;
    @include remSize(padding, 12);
    @include remSize(min-height, 65);
    border-top: 1px solid $grey_200;
    box-sizing: border-box;
    transition: padding $time;

    &.is-opened {
      .tags-list {
        max-height: 0;
        opacity: 0;
        pointer-events: none;
        transition: max-height $time, opacity $time;
      }

      .confirmation {
        @include remSize(height, 74);
        opacity: 1;
        pointer-events: all;
        transition: height $time $time, opacity $time $time * 2;

        @media (max-width: $large-smartphone) {
          @include remSize(height, 138);
        }
      }
    }

    .link {
      position: absolute;
      top: 23px;
      right: 23px;
      @include remSize(width, 18);
    }
  }

  .tags-list {
    display: block;
    height: auto;
    max-height: 1000px;
    max-width: calc(100% - 40px);
    opacity: 1;
    pointer-events: all;
    transition: max-height $time $time * 2, opacity $time $time * 2;
  }

  .tag {
    display: inline-block;
    vertical-align: top;
    position: relative;
    @include remSize(margin, 4);
    @include remSize(padding, 8 4);
    @include fontSize(12);
    font-weight: $regular;
    @include remSize(letter-spacing, 0.2);
    color: $orange_500;
    background-color: rgba(245, 129, 33, 0.08);
    border-radius: 2px;
    white-space: nowrap;
    box-sizing: border-box;
  }

  .confirmation {
    display: block;
    height: 0;
    opacity: 0;
    pointer-events: none;
    transition: height $time $time, opacity $time;

    @media (max-width: $large-smartphone) {
      @include remSize(padding-right, 40);
      box-sizing: border-box;
    }

    .text {
      margin: 0 auto;
      @include remSize(margin-bottom, 16);
      @include fontSize(14);
      font-weight: $medium;
      @include remSize(letter-spacing, 0.2);
      color: $grey_500;
    }

    .button {
      display: inline-block;
      vertical-align: middle;

      + .button {
        @include remSize(margin-left, 14);
      }

      @media (max-width: $large-smartphone) {
        width: 100%;

        + .button {
          @include remSize(margin-top, 8);
          @include remSize(margin-left, 0);
        }
      }
    }
  }
}
