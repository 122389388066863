/*------------------------------------*\
    $LAYOUT
\*------------------------------------*/

#app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.app-wrapper {
  background-color: $baseBg;
  display: table;
  width: 100%;
  height: 100%;
}

.nav-container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10500;
}

.main-container {
  position: absolute;
  left: 56px;
  top: 0;
  right: 0;
  bottom: 0;

  @media (max-width: $large-smartphone) {
    left: 0;

    /* .gmnoprint {
      display: none;
    } */
  }
}

.main-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.map-container {
  height: 100%;
  width: 100%;
}

.data-container {
  position: absolute;
  top: 32px;
  left: 32px;
  width: 400px;
  height: 80vh;
  pointer-events: none;
  > div {
    pointer-events: auto;
  }

  @media (max-width: $large-smartphone) {
    top: 6px;
    left: 16px;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    z-index: $z-index-overlay;

    .button.button-primary.full-width {
      position: absolute;
      bottom: 0;
    }

    .box-block {
      top: -6px;
      left: -16px;
      width: calc(100% + 32px);
      height: calc(100% + 32px);
      max-height: calc(100% + 32px);
    }

    .recent-search {
      top: -16px;
      right: -16px;
      bottom: -16px;
      left: -16px;
    }
  }
}

.wrapper {
  width: $l-width;
  max-width: $l-maxWidth;
  margin: 0 auto;
}

.print-area {
  display: none;
}

/*------------------------------------*\
    $GRIDS
\*------------------------------------*/

@if $grid == inline-block {
  .g {
    font-size: 0;
    > * {
      @include fontSize($font-base);
    }
  }
  .gi {
    @include media-query(lap-and-up) {
      display: inline-block;
      vertical-align: top;
    }
  }
} @else if $grid == float {
  .g {
    @include clearfix;
  }
  .gi {
    float: left;
  }
}
