/*------------------------------------*\
    $NAVIGATION
\*------------------------------------*/

.main-navigation {
  display: block;
  width: 320px;
  height: 100%;
  transition: width 0.3s ease-out;
  will-change: width;
  background: $grey_700;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  .logo {
    transform: translateX(0);
    opacity: 1;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    will-change: transform, opacity;
  }
  .action-menu {
    will-change: font-size, color;
    transition: font-size 0.3s ease-out, color 0.3s ease-out;
  }
  .nav-items-container {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .nav-item {
    @include remSize(padding, 0 16);
    min-height: 48px;
    line-height: 48px;
    margin: 8px 0 0;
    .text {
      max-width: 85%;
      transition: max-width 0.3s ease-out;
      will-change: max-width;
      line-height: 24px;
    }
    /* &:first-child {
      @media (min-width: $large-smartphone + 1) {
        display: none;
      }
    } */
  }
  .logout {
    overflow: hidden;
    border-top: 1px solid $grey_600;
    .nav-item {
      margin-top: 0;
    }
  }
  @media (min-width: $large-smartphone + 1) {
    &.mini {
      width: 56px;
      .logo {
        opacity: 0;
        transform: translateX(-300px);
      }
      .action-menu {
        @include fontSize(24);
        color: $primary;
      }
      .nav-item {
        .text {
          max-width: 0px;
          overflow: hidden;
        }
      }
    }
  }

  @media (max-width: $large-smartphone) {
    &.mini {
      width: 0;

      .logo {
        padding: 0;
      }
    }
  }
}
