/*------------------------------------*\
    $BUTTONS
\*------------------------------------*/

%button {
  -webkit-appearance: none;
  display: inline-block;
  vertical-align: middle;
  @include fontSize(14);
  font-family: $font;
  font-weight: $medium;
  text-align: center;
  line-height: $button-height;
  cursor: pointer;
  width: $button-width;
  @include remSize(padding, 0 23);
  height: $button-height;
  margin: 0;
  background-color: $white;
  color: $black;
  border: none;
  border-radius: 2px;
  &:hover,
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
  &[disabled],
  &.disabled {
    background: #eaeaea;
    pointer-events: none;
  }
  &.full-width {
    width: 100%;
  }
  .icon {
    @include remSize(margin-top, -4);
    @include fontSize(20);
    + span {
      @include remSize(margin-left, 8);
    }
  }
}

%icon-button {
  -webkit-appearance: none;
  text-align: center;
  border: 0;
  background-color: transparent;
  @include inline-block-fix;
  .icon {
    @include inline-block;
    vertical-align: middle;
  }
  &:hover,
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
}

// PRIMARY BUTTON

.button-primary {
  @extend %button;
  background: $primary;
  color: $white;
  &:hover,
  &:active {
    background: $primaryDark;
  }
  &[disabled],
  &.disabled {
    background: $disabled;
  }
}

// SECONDARY BUTTON

.button-secondary {
  @extend %button;
  background: $grey_200;
  color: $grey_600;
  &:hover,
  &:active {
    background: $grey_300;
  }
  &[disabled],
  &.disabled {
    color: $disabledGrey;
  }
}

// TERTIARY BUTTON

.button-tertiary {
  @extend %button;
  background: $white;
  color: $primary;
  box-shadow: 0 0 1px $primary inset;
  &:hover,
  &:active {
    background: $primary;
    color: $white;
  }
  &[disabled],
  &.disabled {
    background: $white;
    color: $disabled;
    box-shadow: 0 0 1px $disabled inset;
  }
}

// MOBILE BUTTON

.button-mobile {
  @extend %button;
  display: block;
  position: relative;
  @include remSize(padding, 20 40 20 12);
  height: auto;
  line-height: 1rem;
  text-align: left;
  background: $white;
  border-bottom: 1px solid $grey_300;
  box-sizing: border-box;

  span {
    display: block;
    @include fontSize(16);
    font-weight: $regular;
    @include remSize(letter-spacing, 0.2);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .icon {
    position: absolute;
    top: calc(50% + 4px);
    right: 12px;
    @include fontSize(16);
    color: $grey_600;
    transform: translateY(-50%);
  }
}

// CLEAN BUTTON

.clean-button {
  border: none;
  background: none;
}

//DELETE ELEMENT

.delete-element {
  white-space: nowrap;
  .content {
    white-space: normal;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    max-width: calc(100% - 51px);
  }
  .delete-button {
    vertical-align: middle;
    display: inline-block;
    padding: 10px;
    margin-left: 10px;
    .icon {
      height: 20px;
      width: 20px;
      background: $grey_200;
      @include fontSize(9);
      color: $grey_400;
      border-radius: 50%;
      line-height: 21px;
    }
  }
}

// BACK BUTTON

.back-button {
  border: none;
  background: none;
}

// LOADING BUTTON

.button-loading {
  cursor: default;
  pointer-events: none;
}

// LOCATION BUTTON

.location-button-container {
  display: none;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate3d(-16px, calc(-50% + 56px), 0);
  z-index: $z-index-overlay;

  @media (max-width: $large-smartphone) {
    display: block;
  }

  .button {
    padding: 0;
    width: 40px;
    height: 40px;
    background-color: $white;
    box-shadow: 0 1px 2px 0 rgba(39, 50, 56, 0.3);

    .icon {
      @include fontSize(24);
      color: $grey_600;
    }
  }
}

/* --- Basics --- */

.loading {
  display: inline-block;
  overflow: hidden;
  height: 100%;
  vertical-align: bottom;
  font-weight: normal;
}

.loading::after {
  display: inline-table;
  white-space: pre;
  text-align: left;
  font-size: 1.5rem;
}

/* --- Types --- */

// TODO Improvement the implementation making it more flexible

@if $loadingAnimation == ellip {
  .loading::after {
    content: '\A.\A..\A...';
    animation: spin4 2s steps(4) infinite;
  }
  @keyframes spin4 {
    to {
      transform: translateY(-$button-height * 4);
    }
  }
} @else if $loadingAnimation == dots {
  .loading::after {
    content: '⠋\A⠙\A⠹\A⠸\A⠼\A⠴\A⠦\A⠧\A⠇\A⠏';
    animation: spin10 1s steps(10) infinite;
  }
  @keyframes spin10 {
    to {
      transform: translateY(-$button-height * 10);
    }
  }
} @else if $loadingAnimation == dots2 {
  .loading::after {
    content: '⠋\A⠙\A⠚\A⠞\A⠖\A⠦\A⠴\A⠲\A⠳';
    animation: spin9 1s steps(9) infinite;
  }
  @keyframes spin9 {
    to {
      transform: translateY(-$button-height * 9);
    }
  }
} @else if $loadingAnimation == bar {
  .loading::after {
    content: '▏\A▎\A▍\A▌\A▋\A▊\A▉';
    animation: spin7 1s steps(7) infinite;
    font-family: sans-serif;
  }
  @keyframes spin7 {
    to {
      transform: translateY(-$button-height * 7);
    }
  }
} @else if $loadingAnimation == circle {
  .loading::after {
    content: '◴\A◷\A◶\A◵';
    animation: spin4 1s steps(4) infinite;
  }
  @keyframes spin4 {
    to {
      transform: translateY(-$button-height * 4);
    }
  }
} @else if $loadingAnimation == beam {
  .loading::after {
    content: '\A=   \A==  \A=== \A====\A ===\A  ==\A   =\A';
    animation: spin9 1.2s steps(9) infinite;
    font-family: monospace;
  }
  @keyframes spin9 {
    to {
      transform: translateY(-$button-height * 9);
    }
  }
} @else if $loadingAnimation == bullet {
  .loading::after {
    content: '●     \A ●    \A  ●   \A   ●  \A    ● \A     ●\A    ● \A   ●  \A  ●   \A ●    ';
    animation: spin10 1s steps(10) infinite;
  }
  @keyframes spin10 {
    to {
      transform: translateY(-$button-height * 10);
    }
  }
} @else if $loadingAnimation == rhomb {
  .loading::after {
    content: '◇◇◇\A◈◇◇\A◇◈◇\A◇◇◈';
    animation: spin4 1s steps(4) infinite;
  }
  @keyframes spin4 {
    to {
      transform: translateY(-$button-height * 4);
    }
  }
} @else if $loadingAnimation == toggle {
  .loading::after {
    content: '⊶\A⊷';
    animation: spin2 1s steps(2) infinite;
  }
  @keyframes spin2 {
    to {
      transform: translateY(-$button-height * 2);
    }
  }
} @else if $loadingAnimation == time {
  .loading::after {
    content: '🕐\A🕑\A🕒\A🕓\A🕔\A🕕\A🕖\A🕗\A🕘\A🕙\A🕚\A🕛';
    animation: spin12 3s steps(12) infinite;
    width: 1.3em;
  }
  @keyframes spin12 {
    to {
      transform: translateY(-$button-height * 12);
    }
  }
}

// /* --- Animations --- */

// @keyframes spin1  { to { transform: translateY( -$button-height ); } }
// @keyframes spin2  { to { transform: translateY( -$button-height * 2 ); } }
// @keyframes spin3  { to { transform: translateY( -$button-height * 3 ); } }
// @keyframes spin4  { to { transform: translateY( -$button-height * 4 ); } }
// @keyframes spin5  { to { transform: translateY( -$button-height * 5 ); } }
// @keyframes spin6  { to { transform: translateY( -$button-height * 6 ); } }
// @keyframes spin7  { to { transform: translateY( -$button-height * 7 ); } }
// @keyframes spin8  { to { transform: translateY( -$button-height * 8 ); } }
// @keyframes spin9  { to { transform: translateY( -$button-height * 9 ); } }
// @keyframes spin10 { to { transform: translateY( -$button-height * 10 ); } }
// @keyframes spin11 { to { transform: translateY( -$button-height * 11 ); } }
// @keyframes spin12 { to { transform: translateY( -$button-height * 12 ); } }
