/*------------------------------------*\
    $ASSET DETAILS
\*------------------------------------*/

.asset-details {
  position: relative;
  display: flex;
  flex-direction: column;
  .buttons-list,
  .document-list {
    @include remSize(padding, 0 24 16);
  }
  .asset-options {
    @include remSize(padding, 0 20);
    &.is-right {
      .asset-options-menu {
        right: 24px;
      }
    }
  }
  .asset-details-content {
    flex: 1;
    overflow: auto;
    .description-table:first-child {
      padding-top: 0;
    }
  }
}
