$time: 0.3s;

.sap-form-wrapper {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  .column {
    display: block;
    position: relative;
    width: calc(50% - 8px);
    text-align: right;
  }

  .work-order-bar-wrapper {
    @include remSize(margin-bottom, 16);
    text-align: left;
  }

  .button {
    @include remSize(min-width, 124);
  }
}

.sap-column-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  height: auto;
  text-align: left;
  background-color: $white;
  border: 1px solid $grey_300;
  border-radius: 2px;
  box-sizing: border-box;
  overflow: hidden;

  + .sap-column-wrapper {
    border-top: 0;
  }

  &.is-opened {
    &::after {
      opacity: 1;
      pointer-events: all;
      transition: opacity $time;
    }

    .confirmation-message {
      opacity: 1;
      pointer-events: all;
      transition: opacity $time $time;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $white;
    opacity: 0;
    pointer-events: none;
    transition: opacity $time $time;
    z-index: 0;
  }

  .content {
    display: block;
    position: relative;
    @include remSize(padding, 16);
    box-sizing: border-box;

    .form-field {
      + .section-title {
        @include remSize(margin-top, 32);
      }
    }

    .section-title {
      margin: 0;
      @include fontSize(16);
      font-weight: $medium;
      @include remSize(letter-spacing, 0.2);
      color: $grey_800;

      + .documents-container,
      + .button,
      + .preview-doc {
        @include remSize(margin-top, 16);
      }
    }

    .documents-container {
      + .button,
      + .hidden-input {
        @include remSize(margin-top, 16);
      }
    }

    .hidden-input {
      position: absolute;
      opacity: 0;
      pointer-events: none;

      + .button {
        @include remSize(margin-top, 16);
      }
    }

    .button {
      width: 100%;
      box-sizing: border-box;
    }

    .preview-doc {
      .link {
        max-width: 100%;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.is-opened {
        @include remSize(height, 208);
      }

      .confirmation {
        .text {
          max-width: none;
        }
      }

      .controls {
        .button {
          display: block;
          width: 100%;

          + .button {
            @include remSize(margin-top, 8);
            @include remSize(margin-left, 0);
          }
        }
      }

      + .preview-doc {
        @include remSize(margin-top, 8);
      }

      + .button {
        @include remSize(margin-top, 16);
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    @include remSize(margin-bottom, 16);

    .title {
      margin: 0;
      @include fontSize(12);
      font-weight: $medium;
      @include remSize(letter-spacing, 0.2);
      color: $grey_300;
    }
  }

  .confirmation-message {
    display: block;
    position: absolute;
    top: 16px;
    left: 0;
    @include remSize(padding, 12 14);
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    opacity: 0;
    pointer-events: none;
    transition: opacity $time;
    z-index: 1;

    .text {
      display: block;
      position: relative;
      margin: 0 auto;
      width: 100%;

      + .controls {
        @include remSize(margin-top, 14);
      }
    }

    .controlls {
      display: block;
      position: relative;
    }

    .button {
      display: inline-block;
      vertical-align: middle;

      + .button {
        @include remSize(margin-left, 14);
      }
    }

    @media (max-width: $large-tablet) {
      .button {
        width: 100%;

        + .button {
          @include remSize(margin-top, 8);
          @include remSize(margin-left, 0);
        }
      }
    }
  }
}

.column-adder-wrapper {
  display: block;
  @include remSize(padding, 32);
  position: relative;
  background-color: $white;
  border: 1px solid $grey_300;
  box-sizing: border-box;

  .button {
    width: 100%;
  }
}

.sap-form-content {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
  max-height: 42px;
  transition: transform $time;
  overflow: hidden;

  &.is-opened {
    max-height: none;
  }

  @media (max-width: $large-smartphone) {
    min-width: calc(200% - 24px);
    padding-right: 12px;
  }

  &:first-child {
    position: sticky;
    top: 24px;
    overflow: visible;
    z-index: 2;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      /* height: 56px; */
      background-color: $grey_100;
      transform: translateY(-100%);
    }

    @media (max-width: $large-smartphone) {
      position: relative;
      top: 0;

      &::before {
        display: none;
      }
    }

    + .sap-form-content {
      @include remSize(margin-top, 0);
    }
  }

  &.initial-status {
    .button {
      margin-left: calc(50% - 120px);
      @include remSize(width, 120);
    }
  }

  .button-container {
    display: block;
    width: 100%;

    .button-primary {
      text-align: left;

      &:last-child {
        position: absolute;
        top: 0;
        left: 0;
        text-align: right;
        background-color: transparent;
        pointer-events: none;

        @media (max-width: $large-smartphone) {
          padding-right: 32px;
          box-sizing: border-box;
        }
      }
    }
  }

  .work-order-bar-wrapper,
  .sap-column-wrapper {
    width: 50%;

    &:first-child {
      border-radius: 0 0 0 2px;
    }

    &:last-child {
      border-radius: 0 0 2px 0;
    }
  }

  .sap-column-wrapper {
    + .sap-column-wrapper {
      border-top: 1px solid $grey_300;
      border-left: 0;
    }
  }

  .column-adder-wrapper {
    width: 50%;

    + .column-adder-wrapper {
      border-left: 0;
    }
  }

  + .sap-form-content {
    @include remSize(margin-top, 16);
  }

  .column-fake {
    display: block;
    position: relative;
    width: 50%;
  }

  .by-year-table-wrapper {
    width: 50%;
  }
}
