.coordinates-input-wrapper {
  display: block;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  &.show-gps {
    @include remSize(padding-right, 32);
  }

  &.disabled {
    .link {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .label {
    display: block;
    position: relative;
    @include remSize(margin-bottom, 5);
    @include fontSize(12);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    color: $grey_600;
  }

  .inputs-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;

    .form-field {
      width: calc(50% - 16px);

      + .form-field {
        margin-top: 0;
        @include remSize(margin-left, 32);

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: -20px;
          @include remSize(width, 8);
          @include remSize(height, 1);
          background-color: $grey_300;
        }
      }
    }
  }

  .link {
    position: absolute;
    right: 0;
    bottom: 10px;
    @include remSize(width, 20);
    @include remSize(height, 20);

    .icon {
      @include fontSize(20);
    }
  }
}
