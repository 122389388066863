/*------------------------------------*\
    $FORMS
\*------------------------------------*/

.form-field {
  position: relative;
}

.helper-text {
  @include fontSize(12);
  @include remSize(padding, 4 8);
  margin: 0;
}

::placeholder {
  color: $grey_300;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $grey_300;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $grey_300;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $grey_300;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $grey_300;
}

// .date-field {
//   &:after {
//     content: '\e903';
//     @extend %icon;
//     @include inline-block;
//     position: absolute;
//     right: 5px;
//     bottom: 20px;
//     font-size: 18px;
//     color: $black;
//     pointer-events: none;
//     touch-events: none;
//   }
// }

.form-label,
.fake-label {
  @include remSize(margin-bottom, 5);
  @include fontSize(12);
  font-weight: $medium;
  color: $grey_600;
  display: block;
  .is-disabled & {
    color: $text;
  }
}
.fake-label {
  margin: 0;
  line-height: normal;
}

.form-input[type='text'],
.form-input[type='email'],
.form-input[type='password'],
.form-input[type='tel'],
.form-input[type='number'],
.form-input[type='date'],
.select,
.textarea {
  position: relative;
  width: 100%;
  @include remSize(padding, 10 16);
  @include fontSize(14);
  font-weight: $regular;
  background: $white;
  color: $text;
  border: $grey_300 1px solid;
  border-radius: 2px;
  box-sizing: border-box;
  &:focus,
  .is-focused & {
    border-color: $grey_400;
  }
  .has-error & {
    border-color: $error;
    color: $error;
  }
  &[disabled] {
    border-color: $disabledGrey;
    border-style: dotted;
    color: $disabled;
  }
}

.form-input[type='number'] {
  -moz-appearance: textfield;
}

.form-input[type='number']::-webkit-inner-spin-button,
.form-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-field.is-fixed {
  .form-input {
    background-color: $orange_700;
    border: 1px solid $orange_200;

    &::placeholder {
      color: $orange_200;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $orange_200;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $orange_200;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $orange_200;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: $orange_200;
    }
  }
}

.textarea {
  width: 100%;
  height: auto;
}

// Search Field

.search-field {
  position: relative;
  max-width: 240px;
  transition: max-width 0.3s ease-out;
  will-change: max-width;
  .input-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    @include fontSize(18);
    color: $grey_300;

    &.clear-input {
      cursor: pointer;
    }
  }
  input {
    width: 100%;
    box-sizing: border-box;
    border: $grey_300 1px solid;
    border-radius: 2px;
    line-height: 40px;
    @include remSize(padding, 0 40 0 18);
    @include fontSize(14);
    font-weight: $regular;
    transition: color 0.2s ease, padding 0.2s ease;
    &::placeholder {
      color: $grey_400;
      font-weight: $medium;
      transition: color 0.2s ease;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      transition: color 0.2s ease;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      transition: color 0.2s ease;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      transition: color 0.2s ease;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      transition: color 0.2s ease;
    }
  }
  &.collapsed {
    max-width: 42px;
    input {
      padding: 0;
      color: $white;
      &::placeholder {
        color: $white;
      }
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $white;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $white;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $white;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: $white;
      }
    }
    .input-icon {
      cursor: pointer;
      color: $grey_600;
      @include fontSize(20);
      margin-top: -1px;
    }
  }
}

// Main-search-field

.main-search-field {
  max-width: 100%;
  input {
    @include remSize(padding, 0 80 0 50);
    border-radius: 0;
    -webkit-appearance: none;
    apperance: none;
  }
  .input-icon {
    width: 50px;
    left: 0;
    right: auto;
    display: none;
  }
  .open-menu {
    color: $grey_700;
    display: block;
    @include fontSize(27);
    .icon {
      margin-top: -4px;
    }
  }
  .location-button {
    position: absolute;
    right: 0;
    top: 0;
    height: 42px;
    line-height: 42px;
    text-align: center;
    @include fontSize(14);
    font-weight: $medium;
    color: $primary;
    @include remSize(padding, 0 14);

    // @media (max-width: $large-smartphone) {
    //   display: none;
    // }
  }
  @media (min-width: $large-smartphone + 1) {
    input {
      @include remSize(padding-left, 45);
      border: none;
      border-bottom: 1px solid $grey_300;
    }
    .input-icon {
      color: $grey_300;
      display: block;
    }
    .open-menu {
      display: none;
    }
  }
}

//Field Select

.field-select {
  position: relative;
  color: $grey_500;
  &:after {
    @extend %icon;
    @extend .icon-chevron-down:before;
    display: inline-block;
    position: absolute;
    right: 13px;
    bottom: 13px;
    font-size: 14px;
    color: $primary;
    pointer-events: none;
    touch-events: none;
  }
  &.clean-select {
    display: inline-block;
    &:after {
      bottom: 0;
      line-height: 18px;
    }
    .form-label {
      display: none;
    }
    .select {
      @include remSize(padding, 0 30 0 0);
      border: none;
      background: transparent;
      color: $grey_600;
      font-weight: $regular;
    }
  }
  &.no-icon-select {
    &:after {
      display: none;
    }
    .form-label {
      display: none;
    }
    .select {
      @include fontSize(13);
      @include remSize(padding, 6 16);
      color: $grey_700;
    }
  }
}

.select {
  cursor: pointer;
  padding-right: 30px;
}

// Checks and Radios
.check-item {
  position: relative;
  @include remSize(margin, 10 0);
  &.disabled {
    opacity: 0.3;
  }

  .label {
    display: flex;
    position: relative;
    @include remSize(padding-left, 26);
    @include fontSize(14);
    font-weight: $regular;
    min-height: 20px;
    line-height: 20px;
    color: $text;
    cursor: pointer;
    @include remSize(letter-spacing, 0.2);
    &:before {
      @extend %icon;
      @include fontSize(20);
      position: absolute;
      top: -1px;
      left: 0;
    }
  }
  &.select-all {
    label {
      font-weight: $medium;
    }
  }
  input[type='checkbox'] {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    height: 100%;
    border: none;
    outline: none;
    &:checked {
      + .label {
        &:before {
          color: $grey_500;
        }
      }
    }
  }
  &.link-check {
    input[type='checkbox'] {
      &:checked {
        + .label {
          &:before {
            color: $primary;
          }
        }
      }
    }
  }
  &.secondary-check {
    input[type='checkbox'] {
      + .label {
        &:before {
          color: $grey_300;
        }
      }
    }
  }
}

.inline-field {
  @include inline-block-fix();
  .check-item {
    display: inline-block;
    vertical-align: middle;
    @include remSize(margin-right, 24);
  }
}

.check-item {
  .label {
    &:before {
      @extend .icon-checkbox:before;
    }
  }
  input[type='checkbox'] {
    &:checked {
      + .label {
        &:before {
          @extend .icon-checkbox-checked:before;
        }
      }
    }
  }
}

// Single check/radio
.check-item {
  &.single {
    min-height: 18px;
    .label {
      @include remSize(padding, 0);
      min-height: 0;
      .text,
      .basic-link {
        @include hide-content;
      }
      &:before {
        position: relative;
        top: auto;
        @include fontSize(18);
      }
    }
  }
}

// FORMS

.filter-form {
  display: block;
  @include remSize(padding-bottom, 16);
  .form-field {
    @include remSize(padding-top, 16);
  }
  @include media-query(lap-and-up) {
    .form-field {
      width: 50%;
      display: inline-block;
      box-sizing: border-box;
      @include remSize(padding-top, 8);
      &:nth-child(even) {
        @include remSize(padding-left, 8);
      }
      &:nth-child(odd) {
        @include remSize(padding-right, 8);
      }
    }
  }
}

// FILE FIELD

.file-field {
  .input-box {
    display: none;
  }
}

//////////// FORMS

.form {
  .form-field + .form-field {
    @include remSize(margin-top, 12);
  }
  .form-field + .has-label {
    @include remSize(margin-top, 32);
  }
  .has-label + .has-label {
    @include remSize(margin-top, 32);
  }
}

.edit-form {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}

.document-form {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  .file-field {
    text-align: right;
    @include remSize(margin-bottom, 24);
  }
}

.form-field {
  &.has-price {
    input {
      text-align: right;
    }
  }
}
