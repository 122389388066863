.tabs-table-wrapper {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .tabs-container {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .react-tabs {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .tabs {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    @include remSize(min-height, 80);
    background-color: $grey_700;
  }

  .tab-item {
    @include remSize(padding, 0 20);
    @include remSize(min-width, 136);
    max-width: 20%;
    @include remSize(height, 60);
    box-sizing: border-box;

    .text {
      color: $grey_400;
    }

    &:hover {
      .text {
        font-weight: $medium;
      }
    }

    &.active {
      .text {
        color: $white;
      }
    }
  }

  .react-tabs__tab-panel {
    @include remSize(padding, 64 30);
    width: calc(100% + 15px);
    height: 100%;
    max-height: calc(100% - 80px);
    background-color: $grey_100;
    box-sizing: border-box;
    overflow: auto;

    .data-table {
      padding-right: 15px;
    }
  }
}
