/*------------------------------------*\
    $ICONS
\*------------------------------------*/

%icon,
.icon {
  display: inline-block;
  vertical-align: middle;
  font-family: 'sgaIcons';
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  speak: none;
  overflow: hidden;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon svg {
  display: block;
  height: 100%;
  max-width: 100%;
}

.no-svg .icon {
  svg {
    display: none !important;
  }
  &:before {
    display: inline-block;
  }
}

//Usage:
//<span className="icon icon-add-new" aria-hidden=true></span>
.icon-notebook:before {
  content: '\e92c';
}

.icon-gps:before {
  content: '\e92b';
}

.icon-eye:before {
  content: '\e92a';
}

.icon-add-asset:before {
  content: '\e929';
}

.icon-hide-down:before {
  content: '\e927';
}

.icon-hide-up:before {
  content: '\e928';
}

.icon-print:before {
  content: '\e926';
}

.icon-add:before {
  content: '\e920';
}
.icon-chevron-right:before {
  content: '\e921';
}
.icon-chevron-left:before {
  content: '\e922';
}
.icon-chevron-down:before {
  content: '\e923';
}
.icon-chevron-up:before {
  content: '\e924';
}
.icon-upload:before {
  content: '\e925';
}

.icon-checkbox:before {
  content: '\e91e';
}
.icon-checkbox-checked:before {
  content: '\e91f';
}
.icon-warning:before {
  content: '\e91a';
}
.icon-info:before {
  content: '\e91b';
}
.icon-success:before {
  content: '\e91c';
}
.icon-map:before {
  content: '\e91d';
}
.icon-search:before {
  content: '\e900';
}
.icon-location:before {
  content: '\e901';
}
.icon-zoom-in:before {
  content: '\e902';
}
.icon-zoom-out:before {
  content: '\e903';
}
.icon-download:before {
  content: '\e904';
}
.icon-delete:before {
  content: '\e905';
}
.icon-move:before {
  content: '\e906';
}
.icon-edit:before {
  content: '\e907';
}
.icon-error:before {
  content: '\e908';
}
.icon-photo:before {
  content: '\e909';
}
.icon-options:before {
  content: '\e90a';
}
.icon-doc:before {
  content: '\e90b';
}
.icon-configuration:before {
  content: '\e90c';
}
.icon-data:before {
  content: '\e90d';
}
.icon-contracts:before {
  content: '\e90e';
}
.icon-road:before {
  content: '\e90f';
}
.icon-home:before {
  content: '\e910';
}
.icon-logout:before {
  content: '\e911';
}
.icon-menu:before {
  content: '\e912';
}
.icon-hide:before {
  content: '\e913';
}
.icon-rotate:before {
  content: '\e914';
}
.icon-star-colored:before {
  content: '\e915';
}
.icon-star-line:before {
  content: '\e916';
}
.icon-time:before {
  content: '\e917';
}
.icon-filters:before {
  content: '\e918';
}
.icon-close:before {
  content: '\e919';
}
