/*------------------------------------*\
    $PREVIEW DOCUMENT
\*------------------------------------*/

$time: 0.3s;

.preview-doc {
  position: relative;
  height: 48px;
  border: 1px solid $grey_200;
  border-radius: 2px;
  background: $white;
  white-space: nowrap;
  overflow: hidden;
  transition: height $time $time;
  will-change: height;

  &.is-opened {
    height: 148px;
    transition: height $time;

    .confirmation {
      opacity: 1;
      transition: opacity $time $time;
    }
  }

  .preview-wrapper {
    position: relative;
  }

  .preview {
    display: inline-block;
    vertical-align: middle;
    @include remSize(padding, 12 14);
    .img-container {
      height: 24px;
      width: 24px;
      border-radius: 2px;
      overflow: hidden;
      position: relative;
    }
    img {
      @include centered(absolute);
      max-width: none;
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .icon {
      @include fontSize(24);
      color: $grey_400;
      display: block;
    }
  }
  .text {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    @include fontSize(14);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    color: $grey_500;
    max-width: calc(100% - 92px);
    p {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .delete-item {
    position: absolute;
    right: 0;
    top: 0;
    @include remSize(padding, 14 10);
    @include fontSize(20);
    color: $grey_300;
    .icon {
      display: block;
    }
  }

  .confirmation {
    display: block;
    position: relative;
    @include remSize(padding, 12 14);
    text-align: center;
    box-sizing: border-box;
    opacity: 0;
    transition: opacity $time;

    .text {
      display: block;
      position: relative;
      margin: 0 auto;
      width: 100%;

      + .controls {
        @include remSize(margin-top, 14);
      }
    }

    .controlls {
      display: block;
      position: relative;
    }

    .button {
      display: inline-block;
      vertical-align: middle;

      + .button {
        @include remSize(margin-left, 14);
      }
    }
  }
}
