/*------------------------------------*\
    $DROPDOWN
\*------------------------------------*/

.dropdown {
  position: relative;
  background: white;
  border-radius: 2px;
  box-shadow: 0 1px 5px 0 rgba(39, 50, 56, 0.4);

  .collapse-button {
    width: 100%;
    text-align: right;
    .icon {
      margin-right: 12px;
      color: $primary;
    }
  }
  .fake-label {
    position: absolute;
    bottom: calc(100% + 5px);
    left: 0;
    width: 100%;
  }
}
.dropdown-title {
  @include fontSize(14);
  font-weight: $regular;
  @include remSize(letter-spacing, 0.2);
  line-height: 40px;
  color: $text;
  @include remSize(padding-left, 16);
  overflow: hidden;
  white-space: nowrap;
}
.dropdown-option {
  @include fontSize(14);
  font-weight: $regular;
  @include remSize(letter-spacing, 0.2);
  text-align: left;
  @include remSize(padding, 10 16);
  color: $grey_600;
  width: 100%;
  &:hover {
    background: $grey_200;
    font-weight: $medium;
  }
}
