.sidebar {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 280px;
  height: 100%;
  background-color: $white;
  border-right: 1px solid $grey_300;
  box-sizing: border-box;
  overflow: hidden;

  .title {
    display: block;
    position: relative;
    margin: 0;
    @include remSize(padding, 24);
    @include fontSize(24);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    color: $grey_800;
  }

  .content {
    display: block;
    position: relative;
    @include remSize(padding, 0 24);
    height: calc(100% - 74px);
    box-sizing: border-box;

    &.has-filters {
      height: calc(100% - 98px);

      .clear-filters-button {
        display: block;
      }
    }
  }

  .select-wrapper {
    + .select-wrapper {
      margin-top: 24px;
    }

    + .filters-container {
      margin-top: 56px;
    }
  }

  .Select {
    border: 1px solid $grey_300;
    box-shadow: none;
    box-sizing: border-box;
  }

  .Select-menu-outer {
    margin-left: -1px;
    width: calc(100% + 2px);
    border: 1px solid $grey_300;
    border-top: 0;
    box-shadow: none;
  }

  .filters-container {
    display: block;
    position: relative;
    @include remSize(margin-left, -24);
    width: calc(100% + 48px);
    height: calc(100% - 204px);
    overflow-y: auto;
  }

  .collapsable-item {
    border-top: 1px solid $grey_200;
    border-bottom: 1px solid $grey_200;
    box-sizing: border-box;

    .check-list {
      padding-bottom: 10px;
    }

    .inline {
      display: inline-block;
      @include remSize(padding-right, 12);
      @include remSize(padding-bottom, 16);
      max-width: calc(50% - 48px);

      .text {
        @include fontSize(12);
      }

      + .inline {
        @include remSize(padding-right, 24);
        @include remSize(padding-left, 12);
      }
    }

    + .collapsable-item {
      border-top: 0;
    }
  }

  .collapsable-header {
    min-height: 60px;
  }

  .collapse-title {
    display: block;
    @include remSize(padding, 20 24 10 20);
    @include fontSize(14);
    font-weight: $medium;
    @include remSize(letter-spacing, 0.2);
    color: $grey_500;
  }

  .collapse-button {
    @include remSize(margin-top, 8);
  }

  .clear-filters-button {
    display: none;
    position: absolute;
    top: 172px;
    right: 24px;
  }
}
