/*------------------------------------*\
    $CARD
\*------------------------------------*/

.block {
  background: $white;
  position: relative;
  border: 1px solid $grey_300;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  .search-field {
    max-width: 100%;
  }
  .close-block {
    position: absolute;
    top: 0;
    right: 0;
    height: 44px;
    width: 44px;
    border: none;
    background: none;
    color: $primary;
  }
  // .check-list {
  //   @include remSize(padding, 0 0 14 24);
  // }
  &.card-block {
    height: 280px;
    .check-item {
      @include remSize(margin, 6 0);
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
  &.box-block {
    .close-block {
      color: $white;
      top: 3px;
    }
  }

  .box-confirmation-message {
    display: none;
  }
}

.block-title {
  @include fontSize(16);
  font-weight: $medium;
  margin: 0;
  @include remSize(padding, 22 24);
  .box-block & {
    color: $grey_400;
    @include remSize(padding, 18 24);
    background: $grey_800;
  }
  .icon,
  img {
    width: 32px;
    @include remSize(margin, -10 15 -6 0);
  }
}

.card-search {
  @include remSize(padding, 0 24 22);
  .search-field {
    max-width: 100%;
  }
}

.block-content-group {
  width: 200%;
  overflow: hidden;
  flex: 1;
  display: flex;
  transition: transform 0.3s ease;
  will-change: transform;
  .block-content {
    width: 50%;
    overflow-y: auto;
    white-space: normal;
  }
  .assets-list-module {
    height: 0;
  }
  &.is-detail-open {
    transform: translateX(-50%);
    .assets-list-module {
      height: calc(100% - 36px);
    }
  }
}

.block-content {
  //overflow: auto;
  flex: 1;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  .react-tabs {
    display: flex;
    flex-direction: column;
    &__tab-panel {
      flex: 1;
      /*overflow: auto;*/
    }
  }
}

.block-actions {
  position: relative;
  background-color: $white;
  border-top: 1px solid $grey_300;
  @include remSize(padding, 24);
  text-align: right;
  button + button {
    @include remSize(margin-left, 24);
  }
  .actions-modal-buttons {
    display: block;
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
  }
}

.block-ratio {
  display: block;
  position: relative;
  @include remSize(padding, 0 24);
  box-sizing: border-box;

  .inputs-field {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    .form-field {
      width: calc(50% - 12px);
    }
  }

  + .without-value-field {
    @include remSize(margin-top, 24);
  }
}

.without-value-field,
.info-ration {
  display: block;
  position: relative;
  @include remSize(padding, 0 24);
  box-sizing: border-box;
}

.info-ration {
  .description {
    display: block;
    position: relative;
    margin: 0 auto;
    @include fontSize(14);
    font-weight: $regular;
    color: $grey_500;

    + .description {
      @include remSize(margin-top, 8);
    }
  }
}
