/*------------------------------------*\
$MODAL
\*------------------------------------*/
$time: 0.3s;

.modal {
  background: rgba($grey_700, 0.8);
  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $white;
    border-radius: 2px;
    box-shadow: 0 1px 5px 0 rgba(39, 50, 56, 0.2);
    &.modal-generic {
      max-height: 80%;
      max-width: 80%;

      @media (max-width: $large-smartphone) {
        max-width: calc(100% - 48px);
      }
    }
    &.modal-edit,
    &.modal-format {
      height: 600px;
      width: 800px;
      max-height: 80%;
      max-width: 80%;

      .form {
        position: relative;
        margin: 0 auto;
        max-width: 320px;
      }

      .document-form {
        max-width: 480px;
      }
    }
    &.modal-edit {
      height: 768px;
    }
    &.modal-carousel {
      min-height: 600px;
      height: 600px;
      width: 800px;
      max-height: 80%;
      max-width: 80%;

      .slider-wrapper {
        margin-top: 110px;
        max-height: 445px;
      }
      .slick-slider {
        padding: 0 110px;
        max-height: 380px;
      }
      .slick-arrow {
        &::before,
        &::after {
          background-color: $orange_500;
        }
      }
      .slick-prev {
        left: 52px;
      }
      .slick-next {
        right: 52px;
      }
      .slider-counter {
        color: $grey_500;
      }

      @media (max-width: $large-smartphone) {
        min-height: 100%;
        max-height: 100%;
        max-width: 100%;

        .slider-wrapper {
          top: calc(50% - 240px);
          max-height: none;
        }

        .slick-slider {
          padding: 0;
          max-height: 240px;
        }

        .slick-arrow {
          display: none !important;
        }
      }
    }
    &.modal-actions {
      height: 768px;
      width: 1000px;
      max-height: 80%;
      max-width: 80%;

      .block-content,
      .react-tabs__tab-panel {
        padding: 0;
        background-color: $grey_100;
      }
    }

    &.modal-format {
      max-height: 520px;

      .block-content {
        background-color: $grey_100;
      }

      .select-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 320px;
        transform: translate3d(-50%, -50%, 0);
      }

      .Select,
      .Select-menu-outer {
        box-shadow: none;
        border: 1px solid $grey_300;
      }

      .Select-menu-outer {
        margin-left: -1px;
        width: calc(100% + 2px);
      }
    }
  }
  .close-modal {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    button {
      height: 44px;
      width: 44px;
    }
    .icon {
      color: $grey_400;
    }
  }
  .confirmation-alert {
    @include remSize(padding, 40 60);

    @media (max-width: $large-smartphone) {
      @include remSize(padding, 24);
    }
    .text {
      @include fontSize(14);
      color: $text;
      text-align: center;
      min-width: 210px;
      max-width: 100%;
      margin: 0 auto;
    }
    .actions {
      @include remSize(margin-top, 36);
    }
  }
  .block {
    height: 100%;
  }
  .box-block {
    border: none;
  }
  &.dark-modal {
    .modal-content {
      background: $grey_800;
    }
    .close-modal {
      .icon {
        color: $grey_200;
      }
    }
  }

  .box-confirmation-message {
    display: block;
    position: absolute;
    top: 55px;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    background-color: $white;
    opacity: 0;
    pointer-events: none;
    transition: opacity $time;

    &.is-opened {
      opacity: 1;
      pointer-events: all;
      z-index: 1;
    }

    .content {
      display: block;
      position: relative;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-100%);
    }

    .text {
      display: block;
      position: relative;
      margin: 0 auto;
      width: 100%;

      + .controls {
        @include remSize(margin-top, 14);
      }
    }

    .controlls {
      display: block;
      position: relative;
    }

    .button {
      display: inline-block;
      vertical-align: middle;

      + .button {
        @include remSize(margin-left, 14);
      }
    }
  }
}
