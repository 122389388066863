$time: 0.3s;

.copy-text-wrapper {
  display: block;
  @include remSize(max-width, 192);

  &:hover {
    .copy-button {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
      transition: opacity $time, transform $time;
      pointer-events: all;
    }
  }

  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .copy-button {
    position: absolute;
    top: 100%;
    left: 50%;
    @include remSize(padding, 12 16);
    @include fontSize(13);
    font-weight: $medium;
    color: $white;
    background-color: $grey_800;
    border-radius: 2px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    opacity: 0;
    transform: translateX(-50%) translateY(8px);
    transition: opacity $time, transform $time;
    pointer-events: none;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -4px;
      left: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent $grey_800 transparent;
      transform: translateX(-50%);
    }
  }
}
