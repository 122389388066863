.filter-container-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @include remSize(padding, 56 0 0 280);
  background-color: $grey_100;
  box-sizing: border-box;

  .header {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    @include remSize(padding, 0 128 0 24);
    box-sizing: border-box;

    .link {
      margin-left: 24px;
      white-space: nowrap;
    }

    .button-tertiary {
      background-color: $white;

      &:hover {
        background-color: $orange_500;

        span {
          color: $white;
        }
      }
    }

    + .content {
      margin-top: 24px;
    }
  }

  .results-text {
    position: relative;
    margin: 0;
    @include fontSize(16);
    font-weight: $regular;
    @include remSize(letter-spacing, 0.2);
    color: $grey_500;

    span {
      font-weight: $semibold;
    }
  }

  .content {
    display: block;
    position: relative;
    @include remSize(padding, 0 128 110 24);
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
  }

  .cards-container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: calc(100% + 16px);

    .check-item {
      display: block;
      @include remSize(margin, 0 0 16 0);
      width: 100%;
    }

    .block {
      width: calc(25% - 18px);
      margin-right: 16px;
      margin-bottom: 16px;
    }
  }

  .select-container {
    display: block;
    @include remSize(margin-top, 24);
    text-align: right;

    .field-select {
      .select {
        @include remSize(padding, 0 24 0 0);
      }

      &:after {
        right: 0;
      }
    }

    + .table-container {
      @include remSize(margin-top, 24);
    }
  }

  .table-container {
    display: block;
    position: relative;
    width: 100%;
    height: calc(100% - 144px);
    overflow: auto;

    + .pagination-container {
      @include remSize(margin-top, 32);
    }
  }

  .pagination-container {
    display: block;
    position: relative;
    text-align: center;
  }

  .controls {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 280px;
    @include remSize(padding, 24 128 24 24);
    min-height: 90px;
    text-align: right;
    background-color: $white;
    border-top: 1px solid $grey_300;
    box-sizing: border-box;

    .button {
      position: relative;
      margin: 0 auto;

      + .button {
        margin-left: 24px;
      }
    }

    .button-primary {
      min-width: 150px;
    }

    .button-secondary {
      min-width: 108px;
    }
  }
}
