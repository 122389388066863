.pagination-list {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;

  .link {
    @include remSize(margin, 0 6);
    color: $grey_400;

    &.prev {
      @include remSize(margin-right, 26);
    }

    &.next {
      @include remSize(margin-left, 26);
    }

    &:hover,
    &.active {
      color: $orange_500;
    }

    &.inactive {
      pointer-events: none;
    }
  }
}
